import React from 'react';
import styles from './time-shift.module.scss';
import classNames from 'classnames';
import {SVGS} from '../../../../assets/images';
import {TimeshiftStatus, TimeshiftType, TiminatorTimeshiftType} from '../../types';
import {IntlShape} from 'react-intl';
import TimeshiftActionMenu from './components/action-menu';
import {Button} from '@mui/material';


interface Props {
    intl: IntlShape;
    hours: number;
    status: TimeshiftStatus;
    note: string;
    type: TimeshiftType;
    size?: 'small' | 'default';
    updateState: (status: TimeshiftStatus, previous: TimeshiftStatus) => void;
    onAddNewShiftClick: () => void;
    onEditShiftClick: () => void;
}

const GetStateTypeIcon = (props: { status: TimeshiftStatus, active: boolean }) => {
    switch (props.status) {
        case TimeshiftStatus.NORMAL:
            return (<SVGS.CheckIcon className={classNames({
                [styles.icon]: true,
                [styles.normal]: true
            })} />)
        case TimeshiftStatus.APPROVED:
            return (<SVGS.ApproveIcon className={classNames({
                [styles.icon]: true,
                [styles.normal]: !props.active
            })} />)
        default:
            return (<SVGS.MoneyIcon className={classNames({
                [styles.icon]: true,
                [styles.normal]: !props.active
            })}/>)
    }
}

export const TimeShift: React.FC<Props> = (props) => {
    const { intl, hours, status, type, note, size } = props;

    const handleApproveClick = () => {
        props.updateState(status === TimeshiftStatus.NORMAL ? TimeshiftStatus.APPROVED : TimeshiftStatus.NORMAL, status);
    }

    const handleBillClick = () => {
        props.updateState(status !== TimeshiftStatus.BILLABLE ? TimeshiftStatus.BILLABLE : TimeshiftStatus.APPROVED, status);
    }

    return(<div className={classNames({
        [styles.container]: true,
        [styles.wrap]: !size || size == 'small'
    })}>
        <div className={classNames({
            [styles.col]: true,
            [styles.wrap]: !size || size == 'small'
        })}>
            <div className={styles.hours}>{hours} h</div>
            {
                size && size == 'default' ? <>
                    <div className={styles.content}>{type.timinatorType}</div>
                    <div className={styles.content}>{note}</div>
                </> : <></>
            }
        </div>
        <div className={classNames({
            [styles.menu]: true,
            [styles.wrap]: !size || size == 'small'
        })}>
            <Button className={classNames({
                [styles.state]: true,
                [styles.approved]: status != TimeshiftStatus.NORMAL,
            })} disabled={type.timinatorType !== TiminatorTimeshiftType.SHIFT} onClick={() => handleApproveClick()}><GetStateTypeIcon status={status !== TimeshiftStatus.NORMAL ? TimeshiftStatus.APPROVED : TimeshiftStatus.NORMAL} active={status !== TimeshiftStatus.NORMAL}/>
            </Button>
            {
                type.timinatorType === TiminatorTimeshiftType.SHIFT && <Button className={classNames({
                    [styles.state]: true,
                    [styles.billable]: status == TimeshiftStatus.BILLABLE
                })} onClick={() => handleBillClick()} ><GetStateTypeIcon status={TimeshiftStatus.BILLABLE} active={status === TimeshiftStatus.BILLABLE}/>
                </Button>
            }
            <TimeshiftActionMenu intl={intl}
                status={status}
                hours={hours}
                timeshiftType={type.timinatorType}
                onStatusChangeClick={props.updateState}
                onAddNewShiftClick={props.onAddNewShiftClick}
                onEditShiftClick={props.onEditShiftClick}/>
        </div>
    </div>)
}