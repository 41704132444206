import React, {useEffect, useState} from 'react'
import SubscriptionBanner from './components/subscription-banner';
import {Box, Link} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {getSubscription} from '../../../../features/billing/subscription';
import {ConnectSubscription} from '../../../../features/billing/subscriptionService';
import {IntlShape, useIntl} from 'react-intl';

const Subscription = (props: { intl: IntlShape }) => {
    const { intl } = props;
    const dispatch = useAppDispatch();
    const {subscription} = useAppSelector(store => store.subscription);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        dispatch(getSubscription()).then(() => {
            setLoading(false);
        })
    }, []);

    const handleConnect = async () => {
        await ConnectSubscription();
    }

    const getSubscriptionText = () => {
        if (subscription.freeDaysCount > 0) {
            return intl.formatMessage({id: 'timinator_trial'});
        }
        return intl.formatMessage({id: 'timinator_connected'});
    }

    return (
        <Box sx={{
            marginTop: '2rem',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1.5rem',
            height: '100%'
        }}>
            {
                !loading && <>
                    <SubscriptionBanner label={getSubscriptionText()} active={subscription.subscriptionEnabled} nextDate={subscription.nextPaymentDate}
                        perHumanPrice={`${subscription.perHumanPrice}${subscription.currency}`} onClick={handleConnect}/>
                    <span>{intl.formatMessage({id: 'get_list_of_features'})} <Link href='https://timinator.app/features' target='_blank'>{intl.formatMessage({id: 'timinator_page'})}</Link></span>
                </>
            }
        </Box>)
}

export default React.memo(Subscription);