import React, { useEffect } from 'react'
import { useAppDispatch } from '../../../../../../../hooks/hooks'
import useModal from '../../../../../../../hooks/useModal';
import DeleteModal from '../../../../../../../components/shared/modal/delete-modal';
import MediaQuery from 'react-responsive';
import IconTextLarge from '../../../../../../../components/shared/large-icon-text';
import { SVGS } from '../../../../../../../assets/images';
import { useIntl } from 'react-intl';
import { deleteWorkCalendar } from '../../../../../../../features/settings/workcalendar';
import { WorkCalendar } from '../../../../../../../features/settings/workcalendar/types';

interface Props {
    workCalendar: WorkCalendar
    onDelete: () => void
}

const DeleteCalendarModal = (props: Props) => {
    const dispatch = useAppDispatch();
    const { open, onToggle, openModal } = useModal();
    const intl = useIntl();

    useEffect(() => {
        openModal();
    }, []);

    const save = () => {
        return dispatch(deleteWorkCalendar(props.workCalendar.id)).then(() => {
            onToggle();
            props.onDelete()
        })
    }

    return <span>
        {
            open && <DeleteModal open={open} title={intl.formatMessage({ id: 'workCalendar' })} onClose={onToggle} onConfirm={save}>
                <MediaQuery maxWidth={1000}>
                    <IconTextLarge text={intl.formatMessage({ id: 'delete_work_calendar' })}
                        subtext={intl.formatMessage({ id: 'delete_work_calendar_info' }, { s: props.workCalendar.name })}
                        icon={<SVGS.DeleteIcon />} />
                </MediaQuery>
                <MediaQuery minWidth={1000}>
                    {intl.formatMessage({ id: 'delete_work_calendar_info' }, { s: props.workCalendar.name })}
                </MediaQuery>
            </DeleteModal>
        }
    </span>
}

export default React.memo(DeleteCalendarModal)