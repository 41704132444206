import {Box, useMediaQuery} from '@mui/material'
import React from 'react'
import NormalCalendar from './components/normal-calendar';
import SmallCalendar from './components/small-calendar';
import MediaQuery from 'react-responsive';

export default function WorkCalendar() {
    return <>
        <Box sx={{ marginTop: { xs: '1.5rem', md: '2rem' }, display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto' }}>
            <MediaQuery minWidth={1000}>
                <NormalCalendar />
            </MediaQuery>
            <MediaQuery maxWidth={1000}>
                <SmallCalendar />
            </MediaQuery>
        </Box>
    </>
}