import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './form-accordion.module.scss';
import {FormCheckbox} from '../form-checkbox';
import {AccordionDetail, AccordionGroup} from './types';
import TSwitch from '../switch';
import {useFormikContext} from 'formik';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

export interface TAccordionProps {
    name: string,
    groups: AccordionGroup[],
    elementType: string,
    optional?: boolean
}
export default function FormAccordion(props: TAccordionProps) {
    const {elementType, name} = props;
    const [groups, change] = React.useState<AccordionGroup[]>(props.groups);
    const {setFieldValue} = useFormikContext();

    const handleChange =
        (value: boolean, item: AccordionDetail) => {
            item.selected = value;
            const selectedValues:number[] = [];
            groups.forEach((group, index) => group.subItems.forEach(detail => {
                if(detail.selected){
                    selectedValues.push(detail.id)
                }
            }));
            setFieldValue(name, selectedValues, false)
            change(prevState => [... prevState])
        };
    
    return (
        <div>
            {
                (groups.map((group, index) => (
                    <Accordion key={`accordion_${index}`}>
                        <AccordionSummary key={`accordion_summary_${index}`}
                            className={styles.hide_focus}
                            expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color:'white' }} />}
                            aria-controls="timinator-accordion-content"
                            id='timinator-accordion_${index}'>
                            <Typography>
                                {group.name}
                                {(props.optional ?? true) && <span className={styles.optional_color}> (Optional)</span>}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {(() => {
                                switch(elementType) {
                                    case 'Switch':
                                        return (group.subItems.map((subItem, subIndex)=> (
                                            <TSwitch key={`tswitch_${index}_${subIndex}`}
                                                checked={subItem.selected}
                                                onChange={value => handleChange(value,subItem)}
                                                label={subItem.name}
                                                name={subItem.name}/>
                                        )))
                                    default:
                                        return (<div>Not supported yet</div>)
                                }
                            })()}
                        </AccordionDetails>
                    </Accordion>)))
            }
        </div>
    );
}
