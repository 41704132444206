import moment from 'moment';
import {REmployees} from '../../features/roles/types';
import {ScheduleType, ScheduleViewType} from '../../features/schedule/types';

export enum TimeshiftStatus {
    NORMAL = 0,
    APPROVED = 1,
    BILLABLE = 2
}

export enum TiminatorTimeshiftType {
    SHIFT = 0,
    ABSENCE = 1
}

export type Timeshift = {
    id: number;
    employeeName: string;
    date: string;
    targetName: string;
    targetId: number;
    note: string;
    internalNote: string;
    hours: number;
    status: TimeshiftStatus;
    timeshiftTypeId: number;
    timeshiftType: TiminatorTimeshiftType;
}

export type Hours = {
    total: number;
    approved: number;
    billable: number;
    all: number;
}

export type DateRange = {
    from: moment.Moment;
    to: moment.Moment;
}

export type TimeshiftType = {
    id: number;
    timinatorType: TiminatorTimeshiftType;
}

export type ScheduleViewStatusFilter = {
    normal: boolean;
    approved: boolean;
    billable: boolean
}

export type TypeIdNameModel = {
    id: number;
    name: string;
    typeName: string;
}

export type TimeshiftUpdateState = {
    timeshiftId: number;
    previousStatus: TimeshiftStatus;
    newStatus: TimeshiftStatus;
}

export type PreEditState = {
    date?: string;
    shiftType?: number;
    project?: number;
    hours?: number;
    comment?: string;
}

export type CreateShift = {
    employees: Array<REmployees>;
    state?: PreEditState;
}

export type EditShift = PreEditState & {
    employee: REmployees;
}

export type ScheduleAutoCompleteItemValue = {
    id: number;
    name: string;
    group?: string;
    originalId: number | string;
}

export interface ScheduleState {
    scheduleType: ScheduleType;
    dateRange: Array<string>;
    scheduleViewType: ScheduleViewType;
    employees: Array<ScheduleAutoCompleteItemValue>;
    projects: Array<ScheduleAutoCompleteItemValue>;
    shiftType: Array<ScheduleAutoCompleteItemValue>;
    absenceTypes: Array<ScheduleAutoCompleteItemValue>;
    statusFilter: ScheduleViewStatusFilter;
    isAnyRowExpand: boolean;
    loading: boolean;
}