import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import HttpClient from '../../../api/api';

export interface Preferences {
    timezone: number,
    startdaytime: string,
    enddaytime: string
}

const initialState: Preferences = {
    timezone: 0,
    startdaytime: '',
    enddaytime: ''
}

export const getTimeZone = createAsyncThunk(
    'preferences/timezone/get',
    async () => {
        const response = await HttpClient.preferencesApi.getTimeZone();
        return response.data;
    }
)

export const updateTimeZone = createAsyncThunk(
    'preferences/timezone/update',
    async (timeZone: number) => {
        const response = await HttpClient.preferencesApi.updateTimeZone(timeZone);
        return response.data;
    }
)

export const getStartDayTime = createAsyncThunk(
    'preferences/start-day-time/get',
    async () => {
        const response = await HttpClient.preferencesApi.getStartDayTime();
        return response.data;
    }
)

export const getEndDayTime = createAsyncThunk(
    'preferences/end-day-time/get',
    async () => {
        const response = await HttpClient.preferencesApi.getEndDayTime();
        return response.data;
    }
)

export const updateStartDayTime = createAsyncThunk(
    'preferences/start-day-time/update',
    async (time: string) => {
        const response = await HttpClient.preferencesApi.updateStartDayTime(time);
        return response.data;
    }
)

export const updateEndDayTime = createAsyncThunk(
    'preferences/end-day-time/update',
    async (time: string) => {
        const response = await HttpClient.preferencesApi.updateEndDayTime(time);
        return response.data;
    }
)

const typeSlice = createSlice({
    name: 'preferences',
    initialState,
    reducers: {
        clearPreferences: (state) => {
            return  initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTimeZone.fulfilled, (state, action) => {
            state.timezone = action.payload
        })
        builder.addCase(getStartDayTime.fulfilled, (state, action) => {
            state.startdaytime = action.payload
        })
        builder.addCase(getEndDayTime.fulfilled, (state, action) => {
            state.enddaytime = action.payload
        })
    }
})

export const { clearPreferences } = typeSlice.actions;

export default typeSlice.reducer;

