import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import HttpClient from '../../../api/api';
import {Year, Month, Day, WorkCalendar, IdNameModel} from './types';
import moment from 'moment';

export interface WorkcalendarState {
    regions: IdNameModel[],
    workCalendars: WorkCalendar[],
    workCalendarId: number,
    year: Year,
    currentMonth: Month,
    currentDay: Day,
    currentYear: number,
    availableYears: IdNameModel[]
}

const getYears = (): IdNameModel[] => {
    const currentYear = moment().year()

    return [
        { id: currentYear - 1, name: `${currentYear - 1}` },
        { id: currentYear, name: `${currentYear}` },
        { id: currentYear + 1, name: `${currentYear + 1}` }
    ]
}

const initialState: WorkcalendarState = {
    regions: [],
    workCalendars: [],
    workCalendarId: 0,
    year: {
        year: 0,
        months: []
    },
    currentMonth: {
        month: 0,
        days: []
    },
    currentDay: {
        day: 0,
        isHoliday: false,
        isShort: false,
        customHours: 0
    },
    currentYear: 0,
    availableYears: getYears()
}

export const getWorkCalendars = createAsyncThunk(
    'work-calendar/getall',
    async () => {
        const response = await HttpClient.workcaledarApi.getAll<WorkCalendar[]>();
        return response.data;
    }
)

export const getRegions = createAsyncThunk(
    'work-calendar/get-regions',
    async () => {
        const response = await HttpClient.workcaledarApi.getRegions<IdNameModel[]>();
        return response.data
    }
)

export const getWorkCalendar = createAsyncThunk(
    'work-calendar/get',
    async (params: { workCalendarId: number, year: number }) => {
        if(params.workCalendarId<1) return initialState.year;
        const response = await HttpClient.workcaledarApi.get<Year>(params.workCalendarId, params.year);
        return response.data;
    }
)

export const getMonth = createAsyncThunk(
    'work-calendar/month/get',
    async (params: { workCalendarId: number, year: number, month: number }) => {
        const response = await HttpClient.workcaledarApi.getMonth<Month>(params.workCalendarId, params.year, params.month);
        return response.data;
    }
)

export const getDay = createAsyncThunk(
    'work-calendar/month/day/get',
    async (params: { workCalendarId: number, year: number, month: number, day: number }) => {
        const response = await HttpClient.workcaledarApi.getDay<Day>(params.workCalendarId, params.year, params.month, params.day);
        return response.data;
    }
)

export const updateMonth = createAsyncThunk(
    'work-calendar/month/update',
    async (params: { workCalendarId: number, year: number, month: Month }) => {
        const response = await HttpClient.workcaledarApi.updateMonth<Month>(params.workCalendarId, params.year, params.month);
        return response.data;
    }
)

export const updateDay = createAsyncThunk(
    'work-calendar/month/day/update',
    async (params: { workCalendarId: number, year: number, month: number, day: Day }) => {
        const response = await HttpClient.workcaledarApi.updateDay<Day>(params.workCalendarId, params.year, params.month, params.day);
        return response.data;
    }
)

export const updateWorkCalendar = createAsyncThunk(
    'work-calendar/update',
    async (params: { workCalendar: WorkCalendar }) => {
        const resposnse = await HttpClient.workcaledarApi.updateWorkCalendar<WorkCalendar>(params.workCalendar)
        return resposnse.data
    }
)

export const createWorkCalendar = createAsyncThunk(
    'work-calendar/create',
    async (params: { workCalendar: WorkCalendar }) => {
        const response = await HttpClient.workcaledarApi.createWorkCalendar<WorkCalendar>(params.workCalendar)
        return response.data
    }
)

export const deleteWorkCalendar = createAsyncThunk(
    'work-calendar/delete',
    async (id: number) => {
        const response = await HttpClient.workcaledarApi.deleteWorkCalendar<WorkCalendar>(id);
        return response.data
    }
)

export const generateWorkCalendar = createAsyncThunk(
    'work-calendar/generate',
    async (args: { id: number, year: number }) => {
        const response = await HttpClient.workcaledarApi.generateWorkCalendar<any>(args.id, args.year);
        return response.data
    }
)

const typeSlice = createSlice({
    name: 'work-calendar',
    initialState,
    reducers: {
        setCurrentYear: (state, action) => {
            state.currentYear = action.payload
        },
        setWorkCalendarId: (state, action) => {
            state.workCalendarId =  action.payload
        },
        clearWorkCalendar: (state) => {
            state.year = initialState.year
        },
        clearWorkCalendarId: (state) => {
            state.workCalendarId = initialState.workCalendarId
        },
        clearCurrentMonth: (state) => {
            state.currentMonth = initialState.currentMonth
        },
        clearCurrentDay: (state) => {
            state.currentDay = initialState.currentDay
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getRegions.fulfilled, (state, action) => {
            state.regions = action.payload
        });
        builder.addCase(getWorkCalendars.fulfilled, (state, action) => {
            state.workCalendars = action.payload
            state.workCalendarId = action.payload?.[0]?.id;
            state.currentYear = moment().year()
        });
        builder.addCase(getWorkCalendar.fulfilled, (state, action) => {
            state.year = action.payload
        });
        builder.addCase(getMonth.fulfilled, (state, action) => {
            state.currentMonth = action.payload
        });
        builder.addCase(getDay.fulfilled, (state, action) => {
            state.currentDay = action.payload
        });
        builder.addCase(updateWorkCalendar.fulfilled, (state, action) => {
            state.workCalendars = refreshWorkCalendars(state.workCalendars, action.payload)
        });
        builder.addCase(createWorkCalendar.fulfilled, (state, action) => {
            state.workCalendarId = action.payload.id
            state.workCalendars.push(action.payload)
        })
    }
})

const refreshWorkCalendars = (calendars: WorkCalendar[], changedCalendar: WorkCalendar): WorkCalendar[] => {
    const found = calendars.find(x => x.id == changedCalendar.id)!
    const refreshedWorkCalendars = removeCalendar(calendars, found).slice()
    refreshedWorkCalendars.push(changedCalendar)
    return refreshedWorkCalendars
}

const removeCalendar = (calendars: WorkCalendar[], toRemove: WorkCalendar): WorkCalendar[] => {
    const index = calendars.indexOf(toRemove)
    if (index > -1){
        calendars.splice(index, 1)
    }

    return calendars
}

export const { setCurrentYear, setWorkCalendarId, clearWorkCalendar, clearCurrentMonth, clearCurrentDay } = typeSlice.actions;

export default typeSlice.reducer;