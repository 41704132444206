import React, { useEffect } from 'react'
import { Form, Formik } from 'formik';
import EditModal from '../../../../../../../../components/shared/modal/edit-modal';
import { FormDropdown } from '../../../../../../../../components/shared/form-controls/form-dropdown';
import FormAutocomplete from '../../../../../../../../components/shared/form-controls/form-autocomplete-tag';
import { useIntl } from 'react-intl';
import { IdNameModel, SIdNameModel, ZohoConfiguration, ZohoIntegration } from '../../../../../../../../features/integrations/zoho/types';
import { ConfigurationModalProps } from '../../../../../../../../features/integrations/types';
import useModal from '../../../../../../../../hooks/useModal';
import { useAppDispatch } from '../../../../../../../../hooks/hooks';
import { configure } from '../../../../../../../../features/integrations/zoho';

interface EditForm {
    employeesForNotification: Array<IdNameModel>,
    employeesForSynchronization: Array<IdNameModel>,
    projectsForSynchronization: Array<IdNameModel>,
    organizationId: SIdNameModel
}

const ZohoConfigurationModal = (props: ConfigurationModalProps) => {
    const { open, onToggle, openModal } = useModal();
    const dispatch = useAppDispatch();
    const { integrationData, integration } = props;
    const zohoIntegration = integrationData as ZohoIntegration
    const configuration = zohoIntegration.configuration
    const intl = useIntl();
    const initialValues: EditForm = {
        employeesForNotification: zohoIntegration.availableEmployeesForNotifications.filter(x => zohoIntegration.configuration.employeesForNotification.includes(x.id)),
        employeesForSynchronization: zohoIntegration.availableEmployeesForSynchronization.filter(x => configuration.employeesForSynchronization.includes(x.id)),
        projectsForSynchronization: zohoIntegration.availableProjects.filter(x => configuration.projectsForSynchronization.includes(x.id)),
        organizationId: zohoIntegration.availableOrganizations.find(x => x.id == configuration.organizationId)!
    }

    const save = (values: EditForm) => {
        const organizationId =  (integrationData.configuration as ZohoConfiguration).organizationId ?? zohoIntegration.availableOrganizations[0].id;        
        const configuration: ZohoConfiguration = {
            organizationId: values.organizationId && values.organizationId.id ? values.organizationId.id : organizationId,
            synchronizationType: 0,
            employeesForSynchronization: values.employeesForSynchronization.map(x => x.id),
            employeesForNotification: values.employeesForNotification.map(x => x.id),
            projectsForSynchronization: values.projectsForSynchronization.map(x => x.id)
        }

        dispatch(configure({ endpoint: integration.endpoints.configure, configuration: configuration })).then(() => {
            onToggle();
            props.onConfigure()
        })
    }
    
    useEffect(() => {
        openModal();
    }, [])

    return (<span>
        {
            open && <Formik
                initialValues={initialValues}
                onSubmit={save}>
                <Form>
                    <EditModal open={open} title={intl.formatMessage({ id: 'integration_name' }, { s: 'Zoho' })} onClose={onToggle}>
                        <FormDropdown required label={intl.formatMessage({ id: 'zoho_organizations' })} name={'organizationId'} items={zohoIntegration.availableOrganizations} />
                        <FormAutocomplete required placeHolder={intl.formatMessage({ id: 'select_one_more_employees' })} label={intl.formatMessage({ id: 'zoho_employees_for_notify' })} name={'employeesForNotification'} items={zohoIntegration.availableEmployeesForNotifications} selectedItems={configuration.employeesForNotification.map(x => ({ id: x, name: '', isDefault: false })) ?? []} />
                        <FormAutocomplete required={false} placeHolder={intl.formatMessage({ id: 'select_one_more_projects' })} label={intl.formatMessage({ id: 'projects' })} name={'projectsForSynchronization'} items={zohoIntegration.availableProjects} selectedItems={configuration.projectsForSynchronization.map(x => ({ id: x, name: '', isDefault: false })) ?? []} />
                        <FormAutocomplete required={false} placeHolder={intl.formatMessage({ id: 'select_one_more_employees' })} label={intl.formatMessage({ id: 'employees' })} name={'employeesForSynchronization'} items={zohoIntegration.availableEmployeesForSynchronization} selectedItems={configuration.employeesForSynchronization.map(x => ({ id: x, name: '', isDefault: false })) ?? []} />
                    </EditModal>
                </Form>
            </Formik>
        }
    </span>)
}

export default ZohoConfigurationModal