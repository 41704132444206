import {Day, IdNameModel} from '../../../../../../../features/settings/workcalendar/types';

export interface EditForm {
    dayType: IdNameModel;
    customHours?: { id: number, name?: string } | undefined;
}

export enum DayType {
    IsWorkingDay = 0,
    IsShortDay = 1,
    IsDayOff = 2
}

export interface Props {
    workCalendarId: number;
    year: number;
    month: number;
    day: Day;
    onUpdate: () => Promise<void>;
}
