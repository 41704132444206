export const Lx = {
    Login: {
        LOGGING_IN: 'logging_in',
        AUTHENTICATION_CODE_CANT_BE_USED: 'authentication_code_can_not_be_used',
        AUTHENTICATION_ISNT_DONE: 'authentication_is_not_done',
        LOGGED_OUT: 'logged_out',
        LOGOUT: 'log_out'
    },
    General: {
        TYPES: 'types',
        PROJECT_TYPES: 'project_types',
        SHIFT_TYPES: 'shift_types',
        ABSENCES_TYPES: 'absences_types',
        ROLES: 'roles',
        EMPLOYEES: 'employees',
        CLIENTS: 'clients',
        PROJECTS: 'projects',
        SETTINGS: 'settings',
        PREFERENCES: 'preferences',
        NOTIFICATIONS: 'notifications',
        INTEGRATIONS: 'integrations',
        EARLYACCESS: 'earlyAccess',
        WORKCALENDAR: 'workCalendar',
        BILLING: 'billing',
        PAYMENTS: 'payments',
        PROFILE: 'profile',
        SCHEDULE: 'schedule',
        CREATE_NEW: 'create_new',
        DELETE: 'delete',
        ME: 'me',
        EMAIL: 'email',
        PHONENUMBER: 'phonenumber',
        SUBSCRIPTION: 'subscription',
        WEB_PAGE: 'timinator_page',
        CALENDAR: 'calendar',
        WORK_CALENDAR: 'work_calendar',
        WORKING_ON_IT: 'we_re_working_on_it',
        TRY_AGAIN: 'try_again',
        UNKNOWN_ERROR: 'unknown_error',
        COLLAPSE: 'collapse',
        COLLAPSE_ALL: 'collapse_all',
        ITEM_OF_ITEM_FORMAT: 'item_of_item',
        SHIFT: 'shift',
        ABSENCE: 'absence',
        TYPE: 'type',
        STATUS: 'status',
        HIDE_MENU: 'hide_menu',
        SHIFT_TYPE: 'shift_type',
        EMPLOYEE: 'employee',
        PROJECT: 'project',
        EXPAND_ALL: 'expand_all',
        SHOW_SMTH: 'show_smth',
        HIDE_SMTH: 'hide_smth',
        REPORT: 'report',
        CREATE: 'create',
        EDIT: 'edit',
        CURRENT_MONTH: 'currentMonth',
        NEXT_MONTH: 'nextMonth',
        PREVIOUS_MONTH: 'previousMonth',
        THIS_WEEK: 'thisWeek',
        LAST_WEEK: 'lastWeek',
        CUSTOM: 'custom',
        PROJECT_TYPE: 'project_type',
        ABSENCE_TYPE: 'absence_type',
        ROLE: 'role',
        CLIENT: 'client'
    },
    Form: {
        REQUIRED: 'required',
        MAX_LENGTH_ERROR: 'max_length_error',
        PHONE_NUMBER_ISNT_VALID: 'phone_number_is_not_valid',
        EMPTY_FIELD_ABILITY: 'empty_field_ability',
        NAME_REQUIRED_FORMAT: 'form_name_required_format',
        N_REQUIRED_FORMAT: 'form_n_required_format'
    },
    Payment: {
        CURRENT_PAYMENTMETHOD: 'currentPaymentMethod',
        PAYMENTMETHOD_SETUP: 'paymentMethod_setup',
        NO_PAYMENTMETHOD_DISP: 'you_have_no_payment_method_added',
        NO_PAYMENTMETHOD_DESC: 'you_have_no_payment_method_added_desc',
        NEW_PAYMENTMETHOD: 'add_payment_method',
    },
    Subscription: {
        TRIAL: 'timinator_trial',
        CONNECTED: 'timinator_connected',
        SUBSCRIPTION_END_FORMAT: 'your_sub_active_unt',
        COST_PER_MONTH_FORMAT: 'per_human_per_month',
        TRY_TRIAL: 'try_timinator',
        NEXT_PAYMENT: 'next_payment',
        FEATURES: 'get_list_of_features',
        TIMINATOR_FREE: 'timinator_free',
        TIMINATOR_PAID: 'timinator_paid'
    },
    Calendar: {
        WORK_DAY: 'standard_work_day',
        DAY_OFF: 'weekend_or_day_off',
        CUSTOM_DAY: 'customized',
        WORK_HOURS: 'work_hours',
        DAY_TYPE: 'day_type',
        DATE_SETTINGS: 'date_settings',
        YEAR: 'year',
        NAME: 'calendarName',
        REGION: 'region',
        WORKHOURS_IN_DAY: 'workHours_in_day',
        WORKHOURS_IN_SHORT_DAY: 'workHours_in_shortDay',
        EDIT_CALENDAR_DESC: 'edit_work_calendar_info',
        DELETE_CALENDAR_DISP: 'delete_work_calendar',
        DELETE_CALENDAR_DESC: 'delete_work_calendar_info'
    },
    Integration: {
        CONNECT: 'integration_connect',
        DISCONNECT: 'integration_disconnect',
        CONFIGURE: 'integration_configure',
        DISCONNECT_TITLE_FORMAT: 'integration_disconnect_title',
        DISCONNECT_DISP: 'integration_disconnect_info',
        DISCONNECT_DESC_FORMAT: 'integration_disconnect_desc',
        DISCONNECT_COMPLETE_FORMAT: 'integration_disconnect_done',
        TITLE_FORMAT: 'integration_name',
        Zoho: {
            LINK: 'zoho_integration_link',
            DESC: 'zoho_integration_desc',
            ORGANIZATIONS: 'zoho_organizations',
            EMPLOYEE_NOTIFY: 'zoho_employees_for_notify',
            CREATED: 'zoho_created_successfully',
            NOT_FOUND: 'zoho_request_not_found'
        },
        WAITING_CONFIGURATION: 'integration_wating_for_configuration',
        SELECT_EMPLOYEES: 'select_one_more_projects',
        SELECT_PROJECTS: 'select_one_more_employees',
        PRESS_BUTTON_TO_BACK: 'press_da_button_kronk'
    },
    Schedule: {
        TYPE_DAY: 'schedule_type_day',
        TYPE_WEEK: 'schedule_type_week',
        TYPE_MONTH: 'schedule_type_month',
        TIMESHIFT_DATE: 'schedule_timeshift_date',
        TIMESHIFT_TARGET: 'schedule_timeshift_target',
        TIMESHIFT_TYPE: 'schedule_timeshift_type',
        TIMESHIFT_NOTE: 'schedule_timeshift_note',
        TIMESHIFT_INTERNAL_NOTE: 'schedule_timeshift_internal_note',
        TIMESHIFT_HOURS: 'schedule_timeshift_hours',
        APPROVE_FORMAT: 'schedule_timeshift_approve',
        APPROVE_ALL_FORMAT: 'schedule_timeshift_approve_all',
        APPROVE_ALL_SHIFT_FORMAT: 'schedule_timeshift_approve_shift',
        NOTHING_TO_APPROVE: 'schedule_nothing_to_approve',
        BILL_FORMAT: 'schedule_timeshift_bill',
        BILL_SHIFT_FORMAT: 'schedule_timeshift_bill_shift',
        BILL_ALL_FORMAT: 'schedule_timeshift_bill_all',
        NOTHING_TO_BILL: 'schedule_nothing_to_bill',
        DISAPPROVE_FORMAT: 'schedule_timeshift_disapprove_all',
        DISAPPROVE_SHIFT_FORMAT: 'schedule_timeshift_disapprove_shift',
        DISAPPROVE_ALL: 'schedule_timeshift_disapprove',
        CANCEL_BILL_FORMAT: 'schedule_timeshift_cancel_bill_all',
        CANCEL_BILL_ALL: 'schedule_timeshift_cancel_bill',
        TOTAL_HOURS: 'schedule_total_hours',
        APPROVED_HOURS: 'schedule_approved_hours',
        BILLABLE_HOURS: 'schedule_billable_hours',
        REGULAR_HOURS: 'schedule_regular_hours',
        EMPLOYEE_VIEW: 'schedule_employee_view',
        PROJECT_VIEW: 'schedule_project_view',
        FILTER_BY_EMPLOYEES: 'schedule_filter_by_employees',
        FILTER_BY_PROJECTS: 'schedule_filter_by_projects',
        FILTER_BY_TYPES: 'schedule_filter_by_types',
        CREATE_REPORT: 'schedule_create_report',
        CREATE_NEW_TIMESHIFT_SHIFT_TO_THIS_DAY: 'schedule_create_timeshift_shift_to_this_day',
        CREATE_NEW_TIMESHIFT_SHIFT: 'schedule_create_timeshift_shift',
        EDIT_TIMESHIFT_SHIFT: 'schedule_edit_timeshift_shift'
    },
    Employee: {
        FIRST_NAME: 'employee_first_name',
        LAST_NAME: 'employee_last_name',
        POSITION: 'employee_position',
        ADMIN: 'employee_admin'
    }
}