import React from 'react'
import {Box, Stack} from '@mui/material';
import DayIcon from '../icon';
import styles from './days-info.module.scss';
import {useIntl} from 'react-intl';

interface DayInfoProps {
    day: number,
    info: string,
    color: string,
}

const DayInfo = (props: DayInfoProps) => {
    const {day, info, color} = props;

    return <Stack direction='row' spacing={1} alignItems='center' className={styles.dayInfo}>
        <Box className={styles.dayIconWrapper}>
            <DayIcon color={color} day={day} disabled={true}/>
        </Box>
        <span>-</span>
        <span>{info}</span>
    </Stack>
}

const DaysInfo = () => {
    const intl = useIntl()
    return <Box display='grid' gridAutoFlow='row' sx={{gridRowGap: '0.5rem'}}>
        <span>Choose a day on month to edit:</span>
        <Box className={styles.dayIcons}>
            <DayInfo day={1} info={intl.formatMessage({id: 'standard_work_day'})} color='#1F1F1F'/>
            <DayInfo day={2} info={intl.formatMessage({id: 'weekend_or_day_off'})} color='#662E2D'/>
            <DayInfo day={4} info={intl.formatMessage({id: 'customized'})} color='#255525'/>
        </Box>
    </Box>
}

export default React.memo(DaysInfo);