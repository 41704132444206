import React, {useEffect} from 'react';
import {useAppDispatch} from '../../../../hooks/hooks';
import useModal from '../../../../hooks/useModal';
import DeleteModal from '../../../../components/shared/modal/delete-modal';
import MediaQuery from 'react-responsive';
import IconTextLarge from '../../../../components/shared/large-icon-text';
import {SVGS} from '../../../../assets/images';
import {deleteClient, getClients} from '../../../../features/clients';
import { Client } from '../../../../features/clients/types';

interface Props {
    client: Client;
}

export default function DeleteClientModal(props: Props) {
    const dispatch = useAppDispatch();
    const {open, onToggle, openModal} = useModal();

    useEffect(() => {
        openModal();
    }, [])

    const save = () => {
        return dispatch(deleteClient(props.client.id)).unwrap().then(() => {
            onToggle();
            dispatch(getClients(0));
        })
    }

    return <>
        {open && <DeleteModal open={open} title='client' onClose={onToggle} onConfirm={save}>
            <MediaQuery maxWidth={1000}>
                <IconTextLarge text='Delete role'
                    subtext={`Are you sure that you want to delete "${props}" client?`}
                    icon={<SVGS.DeleteIcon />} />
            </MediaQuery>
            <MediaQuery minWidth={1000}>
                Are you sure that you want to delete &quot;{props.client.name}&quot; client?
            </MediaQuery>
        </DeleteModal>}
    </>
}
