import React from 'react'
import { SVGS } from '../../../../../assets/images';
import {PopupMenuAction} from '../../../popup-menu/types';
import PopupMenuActions from '../../../popup-menu';

interface Props {
    actions: Array<PopupMenuAction>,
    rowItem: any;
}

export default function TableActions(props: Props) {
    const {actions, rowItem} = props
    return <PopupMenuActions actionIcon={<SVGS.ActionIcon style={{cursor: 'pointer'}}/>} actions={actions} rowItem={rowItem} />
}


