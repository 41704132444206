import styles from './logout-page.module.scss';
import {locationHistory} from '../../helpers/location-history'
import React, {useEffect} from 'react';
import {FormattedMessage} from 'react-intl';

const LogoutPage = () => {
    useEffect(() => {
        setTimeout(() => {
            locationHistory.navigate('/auth');
        }, 1500);
    }, [])
    return <main className={styles.wrapper}>
        {
            <div className={styles.body}>
                <p><FormattedMessage id={'logged_out'} /></p>
            </div>
        }
    </main>
}

export default LogoutPage