import React, {useEffect, useState} from 'react'
import PageWrapper from '../../components/shared/page-wrapper';
import {Box, Tab, Tabs} from '@mui/material';
import TabPanel from '../../components/shared/tab-panel';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {getProjectTabs} from '../../features/projects';
import {PIdNameModel} from '../../features/projects/types';
import ProjectsList from './components/projects-list';
import {getAllEmployees} from '../../features/employees/short';
import {getAllClients} from '../../features/clients/filter';
import {getRoles} from '../../features/roles/filter';
import {FormattedMessage} from 'react-intl';

export default function ProjectsPage() {
    const [value, setValue] = useState<number>(0);
    const dispatch = useAppDispatch();
    const types = useAppSelector(store => store.projects.types);

    
    useEffect(() => {
        dispatch(getProjectTabs())
        dispatch(getAllEmployees())
        dispatch(getAllClients())
        dispatch(getRoles())
    }, [])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }

    return  <PageWrapper>
        <h3><FormattedMessage id={'projects'} /></h3>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} scrollButtons="auto" variant='scrollable'>
                {
                    types.map((type: PIdNameModel) => <Tab disableRipple={true} key={type.id} label={type.name} />)
                }
            </Tabs>
        </Box>
        {
            types.map((type: PIdNameModel, index) => <TabPanel key={type.id} value={value} index={index}>
                <ProjectsList projectType={type}/>
            </TabPanel>)
        }
    </PageWrapper>
};
