import {styled} from '@mui/material';
import {Option as BaseOption} from '@mui/base/Option/Option';
import {optionClasses} from '@mui/base/Option';
import theme from '../../../themes/main/theme';

const Listbox = styled('ul')(
    (theme) => `
  display: flex;
  width: max-content;
  margin-top: 8px;
  padding: 4px 0;
  flex-direction: column;
  align-items: flex-start; 
  overflow: auto;
  outline: 0px;
  background: #1F1F1F;
  color: #565555;
  border-radius: 8px;
  min-width: 156px;
  overflow-y: auto;
  
  font-size: 15.75px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  `,);

export const Option = styled(BaseOption)(
    () => `
  display: flex;
  padding: 14px 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch; 
  cursor: pointer;
  color: #7a7a7a;
  margin: 0;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionClasses.selected} {
    color: #FFF;
  }

  &.${optionClasses.highlighted} {
    color: #FFF;
  }

  &.${optionClasses.highlighted}.${optionClasses.selected} {
    color: #FFF;
  }
  
  &:hover {
    background: #181818;
  }

  &:hover:not(.${optionClasses.disabled}) {
    color: #FFF;
  }
  `,
);

export default Listbox;