import { AxiosInstance, AxiosResponse } from 'axios';

export interface ISettingsIntegrationApi {
    getIntegrations<T>(): Promise<AxiosResponse<T>>
}

export default class SettingsIntegrationApi implements ISettingsIntegrationApi {
    private readonly _axiosInstance;
    private readonly controller = 'integrations';

    constructor(axiosInstance: AxiosInstance) {
        this._axiosInstance = axiosInstance;
    }

    async getIntegrations<T>(): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this.controller}`);
    }
}