import {Box, Button, IconButton, Tooltip} from '@mui/material';
import React, {useState} from 'react';
import styles from './schedule-header.module.scss'
import {SVGS} from '../../../../assets/images';
import ViewSelector from './components/schedule-type-selector';
import {AutoCompleteItemValue} from '../../../../components/shared/nextgen/selector/types';
import {ScheduleType, ScheduleViewType} from '../../../../features/schedule/types';
import Total from '../../components/total';
import ScheduleDateSelector from './components/schedule-date-selector';
import {Hours, ScheduleViewStatusFilter, TimeshiftStatus} from '../../types';
import {Year} from '../../../../features/settings/workcalendar/types';
import {FormattedMessage, IntlShape} from 'react-intl';
import {Lx} from '../../../../i18n/consts';
import ScheduleActionMenu from '../../components/action-menu';
import {TiminatorAbsenceType, TiminatorType} from '../../../../features/types/types';
import Filter from './components/filter';
import ReportModal from '../../components/report-modal';
import {REmployees} from '../../../../features/roles/types';
import {PIdNameModel} from '../../../../features/projects/types';
import moment from 'moment';
import classNames from 'classnames';

interface Props {
    intl: IntlShape;
    scheduleType: ScheduleType;
    employees: REmployees[];
    projects: PIdNameModel[];
    shiftTypes: TiminatorType[];
    absenceTypes: TiminatorAbsenceType[];
    selectedEmployees: AutoCompleteItemValue[];
    selectedProjects: AutoCompleteItemValue[];
    selectedShiftTypes: AutoCompleteItemValue[];
    selectedAbsenceTypes: AutoCompleteItemValue[];
    selectedDateRange: Array<string>;
    selectedViewType: ScheduleViewType;
    year: Year;
    statusFilter: ScheduleViewStatusFilter;
    searchValue: string;
    today: moment.Moment;
    isAllRowsExpanded: boolean;
    // eslint-disable-next-line react-redux/no-unused-prop-types
    scheduleTypeSelected: (selectedType: ScheduleType) => void;
    setEmployeesSelected: (value: AutoCompleteItemValue[]) => void;
    setSelectedProjects: (value: AutoCompleteItemValue[]) => void;
    setSelectedDateRange: (values: string[]) => void;
    setSelectedShiftTypes: (value: AutoCompleteItemValue[]) => void;
    setSelectedAbsenceTypes: (value: AutoCompleteItemValue[]) => void;
    setSelectedViewType: (value: ScheduleViewType) => void;
    setStatusFilter: (value: ScheduleViewStatusFilter) => void;
    setSearchValue: (value: string) => void;
    hours: Hours | null;
    onCreateShiftClick: () => void
    onExpandClick: () => void;
}

const ScheduleTopBar: React.FC<Props> = (props) => {
    const {
        intl,
        employees,
        projects,
        shiftTypes,
        absenceTypes,
        scheduleType,
        selectedEmployees,
        selectedProjects,
        selectedShiftTypes,
        selectedAbsenceTypes,
        hours,
        selectedDateRange,
        selectedViewType,
        year,
        statusFilter,
        searchValue,
        today,
        setEmployeesSelected,
        setSelectedProjects,
        setSelectedShiftTypes,
        setSelectedAbsenceTypes,
        setSelectedDateRange,
        setSelectedViewType,
        setStatusFilter,
        onCreateShiftClick,
        setSearchValue
    } = props;

    return (
        <div className={styles.container}>
            <Box className={styles.header}>
                {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
                <div className={styles.scheduleSelector}>
                    <ViewSelector value={selectedViewType}
                        onChange={(value) => setSelectedViewType(value ?? ScheduleViewType.EMPLOYEEVIEW)}/>
                </div>
                <ScheduleDateSelector scheduleType={scheduleType} selectedDates={selectedDateRange}
                    onRangeSelected={setSelectedDateRange} year={{
                        id: year.year, months: year.months.map((m) => ({
                            id: m.month, days: m.days.map((d) => ({
                                id: d.day,
                                type: d.isShort ? 'short' : d.isHoliday ? 'off' : 'default'
                            }))
                        }))
                    }}/>
                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                    <Button className={styles.actionButton} variant={'scheduleAction'} sx={{ height: '100%' }} onClick={() => onCreateShiftClick()}>
                        <SVGS.PlusIcon className={styles.icon} />
                    </Button>
                    <ReportModal today={today} employees={employees} projects={projects} year={year} />
                </div>
            </Box>
            <Box className={styles.subheader}>
                <div style={{ display: 'flex', gap: '8px', flexDirection: 'row' }}>
                    <Tooltip title={props.isAllRowsExpanded ? intl.formatMessage({ id: Lx.General.COLLAPSE_ALL }) : intl.formatMessage({ id: Lx.General.EXPAND_ALL })}>
                        <IconButton onClick={() => props.onExpandClick()} className={classNames({
                            [styles.collapseButton]: true,
                            [styles.active]: props.isAllRowsExpanded
                        })}>
                            <SVGS.RightChevronIcon className={styles.icon} />
                        </IconButton>
                    </Tooltip>
                    <Filter search={{value: searchValue, onChange: setSearchValue}}
                        employees={{ items: employees.map((x, index) => ({ id: index, name: x.name, originalId: x.id })), setSelected: setEmployeesSelected, selected: selectedEmployees }}
                        projects={{ items: projects.map((x, index) => ({ id: index, name: x.name, originalId: x.id })), setSelected: setSelectedProjects, selected: selectedProjects }}
                        shiftTypes={{ items: shiftTypes, setSelected: setSelectedShiftTypes, selected: selectedShiftTypes }}
                        absenceTypes={{ items: absenceTypes, setSelected: setSelectedAbsenceTypes, selected: selectedAbsenceTypes }}
                        statusFilter={{ status: statusFilter, setFilter: setStatusFilter }}
                        intl={intl} />
                </div>
                <div className={styles.info}>
                    {
                        hours && <Total total={hours.total} allTotal={hours.all} approved={hours.approved} billable={hours.billable}/>
                    }
                </div>
            </Box>
        </div>
    )
}

export default ScheduleTopBar;