import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import HttpClient from '../../../api/api';
import {REmployees} from '../../roles/types';


const initialState: REmployees[] = []

export const getAllEmployees = createAsyncThunk(
    'employees/filter',
    async () => {
        const response = await HttpClient.employeeApi.getAllEmployees<REmployees[]>();
        return response.data
    }
)

const typeSplice = createSlice({
    name: 'rEmployees',
    initialState,
    reducers: {
        clear: (state) => {
            return initialState
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllEmployees.fulfilled, (state, action) => {
            return [...action.payload];
        })
    }
})

export const { clear } = typeSplice.actions

export default typeSplice.reducer;
