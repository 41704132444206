import React, {useEffect} from 'react';
import {useAppDispatch} from '../../../../../../hooks/hooks';
import {
    deleteAbsenceType,
    getAbsencesTypes,
} from '../../../../../../features/types';
import useModal from '../../../../../../hooks/useModal';
import DeleteModal from '../../../../../../components/shared/modal/delete-modal';
import {TiminatorAbsenceType} from '../../../../../../features/types/types';
import MediaQuery from 'react-responsive';
import IconTextLarge from '../../../../../../components/shared/large-icon-text';
import {SVGS} from '../../../../../../assets/images';

interface Props {
    absenceType: TiminatorAbsenceType;
}

const DeleteAbsenceTypesModal = (props: Props) => {
    const dispatch = useAppDispatch();
    const {open, onToggle, openModal} = useModal();

    useEffect(() => {
        openModal();
    }, [])

    const save = () => {
        return dispatch(deleteAbsenceType(props.absenceType.id)).unwrap().then(() => {
            dispatch(getAbsencesTypes(0));
        })
    }

    return <span>
        {open && <DeleteModal open={open} title='absence type' onClose={onToggle} onConfirm={save}>
            <MediaQuery maxWidth={1000}>
                <IconTextLarge text='Delete absence type'
                    subtext={`Are you sure that you want to delete "${props.absenceType.name}" absence type?`}
                    icon={<SVGS.DeleteIcon />} />
            </MediaQuery>
            <MediaQuery minWidth={1000}>
                Are you sure that you want to delete &quot;{props.absenceType.name}&quot; absence type?
            </MediaQuery>
        </DeleteModal>}
    </span>
}

export default React.memo(DeleteAbsenceTypesModal);
