import {Button, styled} from '@mui/material'
import React from 'react'
import style from './day-icon.module.scss'

interface Props {
    day: number,
    color: string,
    hoverColor?: string,
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined,
    disabled?: boolean | true,
}

export default function DayIcon(props: Props) {
    const { day, color, disabled, hoverColor, onClick } = props;

    const DayIconButton = styled(Button)({
        boxShadow: 'none',
        textTransform: 'none',
        fontWeight: 400,
        display: 'flex',
        justifyContent: 'center',
        padding: 'none',
        backgroundColor: color,
        minWidth: 30,
        minHeight: 30,
        maxWidth: 40,
        maxHeight: 40,
        borderRadius: 8,
        pointerEvents: disabled ? 'none' : 'auto',
        fontFamily: [
            'Roboto',
            'sans-serif'
        ].join(','),
        ':hover': {
            backgroundColor: hoverColor
        }
    })

    return <DayIconButton className={style.dayIcon} onClick={onClick}>
        {day}
    </DayIconButton>
}