import {Shift} from '../../../features/shifts/types';
import {Absence} from '../../../features/absences/types';
import {REmployees} from '../../../features/roles/types';
import {useEffect, useState} from 'react';
import {ScheduleViewStatusFilter, Timeshift, TimeshiftStatus, TiminatorTimeshiftType} from '../types';
import {useAppDispatch, useAppSelector} from '../../../hooks/hooks';
import {clearShifts, getShifts} from '../../../features/shifts';
import {clearAbsences, getAbsences} from '../../../features/absences';
import {AutoCompleteItemValue} from '../../../components/shared/nextgen/selector/types';

import _ from 'lodash';
import {ScheduleViewType} from '../../../features/schedule/types';

const getStatus = (s: Shift) => {
    if (s.approved && s.billable) {
        return TimeshiftStatus.BILLABLE;
    } else if (s.approved && !s.billable) {
        return TimeshiftStatus.APPROVED;
    }

    return TimeshiftStatus.NORMAL;
}

const convertShiftsToItemView = (e: REmployees, shifts: Shift[]): Timeshift[] => {
    return shifts.map((s) => ({
        id: s.id,
        employeeName: e.name,
        targetId: s.project.id,
        targetName: s.project.name,
        date: s.date,
        note: s.comment,
        hours: s.hours,
        status: getStatus(s),
        internalNote: s.note,
        timeshiftTypeId: s.shiftType.id,
        timeshiftType: TiminatorTimeshiftType.SHIFT
    }))
}

const convertAbsenceToItemView = (e: REmployees, absences: Absence[]): Timeshift[] => {
    return absences.map((a) => ({
        id: a.id,
        employeeName: e.name,
        targetId: a.absenceType.id,
        targetName: a.absenceType.name,
        date: a.date,
        note: a.comment,
        hours: a.hours,
        status: TimeshiftStatus.NORMAL,
        internalNote: a.note,
        timeshiftTypeId: a.absenceType.id,
        timeshiftType: TiminatorTimeshiftType.ABSENCE
    }))
}

const useScheduleView = (
    dateRange: Array<string>,
    employees: Array<REmployees>,
    selectedEmployees: Array<AutoCompleteItemValue>,
    selectedProjects: Array<AutoCompleteItemValue>,
    selectedShiftTypes: Array<AutoCompleteItemValue>,
    selectedAbsenceTypes: Array<AutoCompleteItemValue>,
    statusFilter: ScheduleViewStatusFilter,
    searchValue: string,
    selectedScheduleViewType: ScheduleViewType
) =>
{
    const dispatch = useAppDispatch();
    const [initial, setInitial] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false);
    const shifts = useAppSelector((selector) => selector.shifts.items);
    const absences = useAppSelector((selector) => selector.absences.items);
    const [timeshifts, setTimeshifts] = useState<Timeshift[]>([]);

    const load = () => {
        setLoading(true)
        const from = dateRange[0].replaceAll('/', '-');
        const to = dateRange[1].replaceAll('/', '-');
        if (selectedAbsenceTypes.length > 0 && selectedShiftTypes.length < 1 && selectedProjects.length < 1) {
            dispatch(clearShifts())
        } else {
            dispatch(getShifts({
                dateFrom: from,
                dateTo: to,
                employees: selectedEmployees?.map((x) => (x.originalId as number)) ?? [],
                projects: selectedProjects?.map((x) => (x.originalId as number)) ?? [],
                types: selectedShiftTypes?.map((x) => (x.originalId as number)) ?? []
            }));
        }
        if ((selectedProjects.length > 0 || selectedShiftTypes.length > 0) && selectedAbsenceTypes.length < 1) {
            dispatch(clearAbsences())
        } else {
            dispatch(getAbsences({
                dateFrom: from,
                dateTo: to,
                employees: selectedEmployees?.map((x) => (x.originalId as number)) ?? [],
                types: selectedAbsenceTypes?.map((x) => (x.originalId as number)) ?? []
            }))
        }
    }

    useEffect(() => {
        if (initial) {
            setInitial(false);
            load()
            return;
        }

        const timeout = setTimeout(() => load(), 500);
        return () => {
            clearTimeout(timeout);
        };
    }, [dateRange, employees, selectedEmployees, selectedProjects, selectedShiftTypes, selectedAbsenceTypes]);

    useEffect(() => {
        if (!shifts && !absences) {
            return;
        }
        const toSearch = new RegExp(searchValue.toLowerCase());
        const scheduleViewData: Timeshift[] = employees.map((e) => {
            const employeeShifts = convertShiftsToItemView(e, shifts.filter((s) => s.employeeId == e.id));
            const employeeAbsences = convertAbsenceToItemView(e, absences.filter((a) => a.employeeId == e.id));
            return [...employeeShifts, ...employeeAbsences].filter((x) => (x.status === TimeshiftStatus.NORMAL && statusFilter.normal) || (x.status === TimeshiftStatus.APPROVED && statusFilter.approved) || (x.status === TimeshiftStatus.BILLABLE && statusFilter.billable));
        }).flat(1);
        setTimeshifts(_.chain(scheduleViewData).filter((s) => {
            if (searchValue === '') {
                return true
            }

            return toSearch.test(s.employeeName.toLowerCase()) || toSearch.test(s.targetName.toLowerCase()) || (s.note !== undefined && s.note !== null && toSearch.test(s.note.toLowerCase()));
        }).orderBy((x) => x.employeeName && x.date).orderBy((x) => selectedScheduleViewType === ScheduleViewType.PROJECTVIEW && x.timeshiftType).value());
    }, [shifts, absences, statusFilter, searchValue, selectedScheduleViewType]);

    return {timeshifts, shifts, absences};
}

export default useScheduleView;