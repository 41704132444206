import {Shift} from './types';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import HttpClient from '../../api/api';

export interface ShiftsState {
    items: Shift[];
}

const initialState: ShiftsState = {
    items: []
}

export const getShift = createAsyncThunk(
    'shifts/get',
    async (id: number) => {
        const response = await HttpClient.shiftsApi.get<Shift>(id);
        return response.data;
    }
)

export const getShifts = createAsyncThunk(
    'shifts/get-many',
    async (args: { dateFrom: string, dateTo: string, employees: Array<number>, projects: Array<number>, types: Array<number> }) => {
        const response = await HttpClient.shiftsApi.getMany<Shift[]>(args.dateFrom, args.dateTo, args.employees, args.projects, args.types);
        return response.data;
    }
)

export const createShift = createAsyncThunk(
    'shifts/create',
    async (shift: Shift) => {
        const response = await HttpClient.shiftsApi.create<Shift>(shift);
        return response.data;
    }
)

export const updateShift = createAsyncThunk(
    'shifts/update',
    async (shift: Shift) => {
        const response = await HttpClient.shiftsApi.update<Shift>(shift.id, shift);
        return response.data
    }
)

export const cancelApproved = createAsyncThunk(
    'shifts/cancel-approved',
    async (shift: Shift) => {
        if (shift.billable) {
            await HttpClient.shiftsApi.updateBillable(shift.id, false);
        }

        const response = await HttpClient.shiftsApi.updateApproved<Shift>(shift.id, false);
        return response.data;
    }
)

export const cancelBillableShift = createAsyncThunk(
    'shifts/cancel-billable',
    async (shift: Shift) => {
        const response = await HttpClient.shiftsApi.updateBillable<Shift>(shift.id, false);
        return response.data;
    }
)

const shiftsSlice = createSlice({
    name: 'shifts',
    initialState,
    reducers: {
        clearShifts: (state) => {
            state.items = initialState.items;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getShifts.fulfilled, (state, action) => {
            state.items = action.payload;
        });
        builder.addCase(updateShift.fulfilled, (state, action) => {
            if (state.items && state.items.length > 0) {
                const index = state.items.findIndex((x) => x.id === action.payload.id);
                if (index != -1) {
                    state.items[index] = action.payload;
                    return
                }
            }

            state.items = [action.payload];
        })
        builder.addCase(createShift.fulfilled, (state, action) => {
            state.items.push(action.payload)
        })
    }
})

export const { clearShifts } = shiftsSlice.actions;

export default shiftsSlice.reducer;