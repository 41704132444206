import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { logout } from '../features/auth/authSplice'
import {store} from '../store/store'
import {showSnack} from '../features/snacks/snackService'
import {ErrorData} from './types';

export const configureAxios = (obsolete?: boolean): AxiosInstance => {
    const axiosInstance: AxiosInstance = axios.create()
    const host: string = obsolete ? typeof process.env.REACT_APP_BACKEND_OBSOLETE_URL == 'string' ? process.env.REACT_APP_BACKEND_OBSOLETE_URL : ''
        : typeof process.env.REACT_APP_BACKEND_URL == 'string' ? process.env.REACT_APP_BACKEND_URL : ''
    axiosInstance.defaults.baseURL = host

    axiosInstance.interceptors.response.use((response: AxiosResponse) => {
        return response
    }, async (error: AxiosError<ErrorData[]>): Promise<AxiosResponse<any>> => {
        if (!error.response) {
            return Promise.reject(error);
        }

        const freeRequests = ['login']
        const isFreeRequest = error.config.url && freeRequests.includes(error.config.url.split('?')[0])
        if ([401,403].includes(error.response?.status)) {
            if (authToken() && !isFreeRequest) {
                const logoutMe = () => store.dispatch(logout())
                logoutMe()
            }
        }
        const message = error.response.data.length > 0 ? error.response.data[0].messages[0] : error.response.statusText;
        if (message !== '') {
            showSnack(message, 'error')
        }

        return Promise.reject(error)
    })

    axiosInstance.interceptors.request.use((config)=>{
        const token = authToken()
        const isLoggedIn = !!token
        const freeRequests = ['login']
        const isFreeRequest = config.url && freeRequests.includes(config.url.split('?')[0])
        if (isLoggedIn && !isFreeRequest) {
            config['headers'] = config.headers ?? {};
            config.headers['Authorization'] = `Bearer ${token}`;
        } else {
            config['headers'] = config.headers ?? {};
        }
        if (!isLoggedIn && !isFreeRequest) {
            config.signal = AbortSignal.timeout(0);
            return config;
        }
        return config
    })

    return axiosInstance
}

function authToken() {
    return store.getState().auth.token
}
