import React, { ReactElement } from 'react'
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { IRouteItem } from '../../../../routes/interfaces/IRouteItem';
import styles from './menu-item.module.scss';
import { getRootUrl } from '../../../../misc';
import { FormattedMessage } from 'react-intl';

interface BaseMenuItemProps {
    /**
     * Uses as LocalizationKey
     */
    name: string
    defaultSvg?: ReactElement;
    secondarySvg?: ReactElement;
    url?: string;
    open?: boolean;
    callback?: () => void;
}

export const BaseMenuItem: React.FC<BaseMenuItemProps> = (props): ReactElement => {
    const { name, defaultSvg, secondarySvg, url, open, callback } = props;

    return (
        <Link to={url ? url : '#'} onClick={callback ? callback : void (0)} className={classNames({
            [styles.menuItem]: true,
            [styles.active]: url ? url === getRootUrl(location.pathname) : false
        })}>
            { !secondarySvg ? <span className={styles.svg}>{defaultSvg}</span> : open !== undefined ? open ? <span className={styles.svg}>{secondarySvg}</span> : <span className={styles.svg}>{defaultSvg}</span> : <span className={styles.svg}>{defaultSvg}</span> }
            {name && open === undefined || open ? <span className={styles.text}><FormattedMessage id={name} /></span> : <></>}
        </Link>
    )
}

interface MenuItemProps {
    route: IRouteItem,
    open?: boolean;
    callback?: () => void;
}

export const MenuItem: React.FC<MenuItemProps> = (props): ReactElement => {
    const { route, open, callback } = props;

    return (<BaseMenuItem name={route.name} defaultSvg={route.svg} url={route.url} open={open} callback={callback} />)
}