import React from 'react';
import {PopupMenuAction, PopupMenuSize} from '../../../../components/shared/popup-menu/types';
import {SVGS} from '../../../../assets/images';
import PopupMenuActions from '../../../../components/shared/popup-menu';
import {Lx} from '../../../../i18n/consts';
import {IntlShape} from 'react-intl';

interface Props {
    intl: IntlShape;
    size?: PopupMenuSize;
    approvedHours?: number;
    billableHours?: number;
    onDisapproveAllClick?: () => void;
    onCancelBillAllClick?: () => void;
    onCreateNewShift?: () => void;
}

const ScheduleActionMenu: React.FC<Props> = (props) => {
    const {intl, size, approvedHours, billableHours, onDisapproveAllClick, onCancelBillAllClick} = props;
    const actions: PopupMenuAction[] = []
    if (approvedHours) {
        actions.push({
            name: intl.formatMessage({id: Lx.Schedule.DISAPPROVE_FORMAT}, {s: approvedHours}),
            icon: <SVGS.DisapproveIcon/>,
            callback: () => onDisapproveAllClick?.(),
        })
    }
    if (billableHours) {
        actions.push({
            name: intl.formatMessage({id: Lx.Schedule.CANCEL_BILL_FORMAT}, {s: billableHours}),
            icon: <SVGS.MoneyOffIcon/>,
            callback: () => onCancelBillAllClick?.()
        })
    }

    actions.push({
        name: intl.formatMessage({id: Lx.Schedule.CREATE_NEW_TIMESHIFT_SHIFT}),
        icon: <SVGS.PlusIcon/>,
        callback: () => props.onCreateNewShift?.()
    })

    return (<PopupMenuActions actionIcon={<SVGS.ActionIcon/>} actions={actions} size={size} newGen/>)
}

export default ScheduleActionMenu;