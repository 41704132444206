import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import HttpClient from '../../api/api';
import {PermissionGroup} from './types';

const initialState: PermissionGroup[] = []

export const getPermissions = createAsyncThunk(
    'permissions/get',
    async () => {
        const response = await HttpClient.permissionApi.getAll<PermissionGroup[]>();
        return response.data
    }
)

const typeSplice = createSlice({
    name: 'permissions',
    initialState,
    reducers: {
        clearRoles: (state) => {
            return initialState
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPermissions.fulfilled, (state, action) => {
            return [... action.payload];
        })
    }
})

export const { clearRoles } = typeSplice.actions

export default typeSplice.reducer;
