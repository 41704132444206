import { Box } from '@mui/material';
import React, { ReactNode } from 'react';
import styles from './large-icon-text.module.scss'

interface Props {
    text: string;
    icon: ReactNode;
    subtext?: string;
}

export default function IconTextLarge(props: Props) {
    return <Box className={styles.wrapper}>
        <Box mb={3} className={styles.icon}>{props.icon}</Box>
        <Box mb={1} className={styles.text}>{props.text}</Box>
        {props.subtext && <Box className={styles.subtext}>{props.subtext}</Box>}
    </Box>
}
