import React, {StrictMode, useEffect, useState} from 'react'
import PageWrapper from '../../components/shared/page-wrapper'
import TiminatorTable from '../../components/shared/table';
import IconTextLarge from '../../components/shared/large-icon-text';
import {SVGS} from '../../assets/images';
import {Box} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {useTableData} from '../../hooks/useTableData';
import {Column} from '../../components/shared/table/types';
import Status from '../../components/shared/status';
import {store} from '../../store/store';
import {selectIdOnDelete, selectIdOnEdit} from '../../features/modals';
import {Role} from '../../features/roles/types';
import {getRoles} from '../../features/roles';
import DeleteRoleModal from './components/delete-modal';
import EditRoleModal from './components/edit-modal';
import CreateRoleModal from './components/create-modal';
import {getPermissions} from '../../features/permissions';
import {getAllEmployees} from '../../features/employees/short';
import {FormattedMessage} from 'react-intl';
import {PopupMenuAction} from '../../components/shared/popup-menu/types';

const columns: Column[] = [
    {
        id: 'name',
        label: 'Name',
        format: (rowData: Role) => {
            return <span>
                {rowData.name}
            </span>
        }
    },
    {
        id: 'status',
        label: 'Status',
        isStatus: true,
        align: 'left',
        format: (rowData: Role) => <Status color={rowData.isActive ? '#59CA59' : '#F26D6B'} value={rowData.isActive ? 'Active' : 'Suspended'} />
    },
];

const actions: PopupMenuAction[] = [
    {
        name: 'Edit',
        icon: <SVGS.EditIcon />,
        callback: function(rowData: Role) {
            store.dispatch(selectIdOnEdit(rowData))
        },
    },
    {
        name: 'Delete',
        icon: <SVGS.DeleteIcon />,
        callback: function(rowData: Role) {
            store.dispatch(selectIdOnDelete(rowData))
        },
    }
]

const mapData = (item: Role): Role => item

const sort = (a: Role, b: Role) => {
    if (a.isActive !== b.isActive) {
        return a.isActive ? -1 : 1;
    } else {
        return a.name > b.name ? 1 : -1;
    }
}

export default function RolesPage() {
    const dispatch = useAppDispatch();
    const roles = useAppSelector(store => store.roles)
    const selectedEdit = useAppSelector(store => store.modals.selectedEdit)
    const selectedDelete = useAppSelector(store => store.modals.selectedDelete)
    const employees = useAppSelector(store => store.rEmployees)
    const permissions = useAppSelector(store => store.permissions)

    const tableSettings = useTableData(columns, actions, roles.items, roles.total, mapData, sort);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);

    const fetchData = async (page: number, searchString?: string) => {
        dispatch(getRoles(page))
    }

    useEffect(() => {
        setIsEmpty(roles.total === 0);
    }, [roles.total])

    useEffect(() => {
        dispatch(getPermissions());
        dispatch(getAllEmployees());
    },[])

    return  <PageWrapper>
        <h3><FormattedMessage id={'roles'} /></h3>
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', overflowY: 'auto'}}>
            {
                !isEmpty
                    ? <TiminatorTable tableSettings={tableSettings} fetchData={fetchData} offset={roles.offset}/>
                    : <IconTextLarge text='You have no roles' subtext='Create role and use it for employees' icon={<SVGS.RolesIcon />} />
            }
            <CreateRoleModal permissions={permissions} employees={employees}/>
            {selectedEdit && <EditRoleModal role={selectedEdit as Role} permissions={permissions} employees={employees}/>}
            {selectedDelete && <DeleteRoleModal role={selectedDelete as Role}/>}
        </Box>
    </PageWrapper>
};
