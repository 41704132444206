import { AxiosInstance, AxiosResponse } from 'axios';

export interface IEarlyAccessApi {
    getEarlyAccessEnabled<T extends boolean>(): Promise<AxiosResponse<T>>,
    updateEarlyAccessEnabled<T extends boolean>(enabled: T): Promise<AxiosResponse<T>>
}

export default class EarlyAccessApi implements IEarlyAccessApi {
    private readonly _axiosInstance: AxiosInstance;
    private readonly controller = 'early-access';

    constructor(axiosInstance: AxiosInstance) {
        this._axiosInstance = axiosInstance;
    }

    async getEarlyAccessEnabled<T extends boolean>(): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this.controller}`);
    }

    async updateEarlyAccessEnabled<T extends boolean>(enabled: T): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.put<T>(`${this.controller}`, JSON.stringify(enabled), { headers: { 'Content-Type': 'application/json' } });
    }
}