import React, {useEffect, useState} from 'react'
import {Box, Button, Grid, Stack} from '@mui/material';
import {Day, WorkCalendar, isCalendar, isNotEmptyCalendar} from '../../../../../../features/settings/workcalendar/types';
import moment from 'moment';
import {
    getMonth,
    getRegions,
    getWorkCalendar,
    getWorkCalendars,
    setCurrentYear,
    setWorkCalendarId,
} from '../../../../../../features/settings/workcalendar';
import {store} from '../../../../../../store/store';
import {selectIdOnDelete, selectIdOnEdit} from '../../../../../../features/modals';
import {useAppDispatch, useAppSelector} from '../../../../../../hooks/hooks';
import EditMonthDayModal from '../day/edit-modal';
import DaysInfo from '../day/info';
import {Dropdown} from '../../../../../../components/shared/dropdown';
import styles from './normal-calendar.module.scss';
import {styled} from '@mui/material/styles';
import theme from '../../../../../../themes/main/theme';
import {FormattedMessage, useIntl} from 'react-intl';
import {SVGS} from '../../../../../../assets/images';
import EditCalendarModal from '../calendar/edit-modal';
import CreateCalendarModal from '../calendar/create-modal';
import DeleteCalendarModal from '../calendar/delete-modal';
import { MonthDay, isMonthDay } from '../../types';
import {Calendar} from '../../../../../../components/shared/calendar';
import {Lx} from '../../../../../../i18n/consts';

const NormalCalendar = () => {
    const intl = useIntl()
    const dispatch = useAppDispatch();
    const { workCalendars, workCalendarId, year, regions, currentYear, availableYears } = useAppSelector(store => store.workCalendar);
    const selectedEdit = useAppSelector(store => store.modals.selectedEdit);
    const selectedDelete = useAppSelector(store => store.modals.selectedDelete);
    const [loading, setLoading] = useState<boolean>(true);
    const availableWorkCalendars = workCalendars.map((x) => {
        return availableYears.map((y) => ({ calendar: x.id, year: y.id, name: `${x.name} - ${y.name}` }))
    }).flat(1)
    const [selectedCalendar, setSelectedCalendar] = useState<number>(() => {
        const currY = moment().year();
        const currentCalendarIndex = availableWorkCalendars.findIndex((x) => x.calendar == 1 && x.year === currY);
        if (currentCalendarIndex < 0) return 1;

        return currentCalendarIndex;
    })

    const handleCalendarSelect = (value: string) => {
        console.log('calendar selected', value, availableYears)
        const id = parseInt(value);
        setSelectedCalendar(id);
        const calendarObj = availableWorkCalendars[id];
        dispatch(setWorkCalendarId(calendarObj.calendar))
        dispatch(setCurrentYear(calendarObj.year))
    }

    const handleDayClick = (month: number, day: Day) => {
        dispatch(getMonth({ workCalendarId: workCalendarId, year: currentYear, month: month })).unwrap().then(() => {
            store.dispatch(selectIdOnEdit({ month: month, day: day } as MonthDay));
        })
    }

    const handleDayUpdateFinished = async () => {
        setLoading(true);
        dispatch(getWorkCalendar({workCalendarId: workCalendarId, year: currentYear}))
            .unwrap()
            .then(() => setLoading(false));
    }

    const handleCalendarEdit = () => {
        const workCalendar = workCalendars.filter(w => w.id == workCalendarId)[0]
        store.dispatch(selectIdOnEdit(workCalendar))
    }

    const handleCalendarCreate = () => {
        const emptyCalendar: WorkCalendar = {
            id: 0,
            name: '',
            region: 0,
            workHours: 0,
            shortWorkHours: 0
        }
        store.dispatch(selectIdOnEdit(emptyCalendar))
    }

    const handleCalendarCreated = async () => {
        // return await fetchWorkCalendar()
    }

    const handleCalendarDelete = () => {
        const workCalendar = workCalendars.find(w => w.id == workCalendarId)!
        store.dispatch(selectIdOnDelete(workCalendar))
    }

    const handleCalendarDeleted = async () => {
        return await dispatch(getWorkCalendars())
    }

    const fetchData = async (workCalendarId: number, year: number) => {
        setLoading(true);
        dispatch(getWorkCalendar({workCalendarId: workCalendarId, year: year}))
            .unwrap()
            .then(() => setLoading(false));
    }

    useEffect(() => {
        setLoading(true)
        dispatch(getRegions())
        dispatch(getWorkCalendars()).unwrap().then(() => {
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if(workCalendarId < 1) return;
        fetchData(workCalendarId, currentYear)
    }, [workCalendarId, currentYear])

    return <>
        {
            !loading && <Stack direction='column' spacing={5} sx={{ overflowY: 'auto', display: 'flex', flexDirection: 'column', marginBottom: '2.5rem', alignItems: 'flex-start' }}>
                <Stack alignItems='start' direction='column' spacing={2} width={'100%'}>
                    <Box display='flex' flexDirection={'row'} gap={'8px'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
                        <Box display={'flex'} flexDirection={'row'} gap={'8px'}>
                            <Box display='flex' alignItems={'center'} minWidth={'8rem'}>
                                {
                                    workCalendars && <Dropdown required={true} style={{marginBottom: 0, minWidth: '10rem'}} name='calendar'
                                        items={availableWorkCalendars.map((x, index) => ({id: index, name: x.name}))}
                                        value={selectedCalendar}
                                        onChange={(value) => handleCalendarSelect(value)}/>
                                }
                            </Box>
                            <Box display='flex' gap={'8px'} alignSelf={'center'} height={'100%'}>
                                <Button className={styles.action}
                                    variant='scheduleAction' 
                                    onClick={() => handleCalendarEdit()}>
                                    <SVGS.EditIcon className={styles.icon + ' ' + styles.small} />
                                </Button>
                                {
                                    workCalendarId > 1 && <Button variant='scheduleAction' className={styles.action}
                                        onClick={() => handleCalendarDelete()}>
                                        <SVGS.DeleteIcon className={styles.icon} />
                                    </Button>
                                }
                            </Box>
                        </Box>
                        <Button variant='scheduleAction' onClick={() => handleCalendarCreate()} className={styles.actionButton}>
                            <SVGS.PlusIcon className={styles.icon} /><span style={{marginLeft: 10}}><FormattedMessage id={Lx.General.CREATE_NEW} /></span>
                        </Button>
                    </Box>
                    <DaysInfo />
                </Stack>
                {
                    workCalendars && year && year.months.length > 0 && <Calendar year={{
                        id: year.year,
                        months: year.months.map((m) => ({
                            id: m.month,
                            days: m.days.map((d) => ({
                                id: d.day,
                                type: d.isHoliday ? 'off' : d.isShort ? 'short' : 'default'
                            }))
                        }))
                    }} view={'month'} size={'full'} onDateClick={(y, month, day) => handleDayClick(month, year.months.find((x) => x.month === month)!.days.find(x => x.day === day)!)} />
                }
            </Stack>
        }
        <Box position={'absolute'}>
            {selectedEdit && isCalendar(selectedEdit as WorkCalendar) && <CreateCalendarModal regions={regions} onCreate={handleCalendarCreated} />}
            {selectedEdit && isNotEmptyCalendar(selectedEdit as WorkCalendar) && <EditCalendarModal regions={regions} calendar={selectedEdit as WorkCalendar} />}
            {selectedEdit && isMonthDay(selectedEdit as MonthDay) && <EditMonthDayModal day={(selectedEdit as MonthDay).day} year={currentYear} month={(selectedEdit as MonthDay).month} workCalendarId={workCalendarId} onUpdate={handleDayUpdateFinished} />}
            {selectedDelete && <DeleteCalendarModal workCalendar={selectedDelete as WorkCalendar} onDelete={handleCalendarDeleted} />}
        </Box>
    </>
}

export default React.memo(NormalCalendar)