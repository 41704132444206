import React from 'react'
import {useAppDispatch} from './hooks'
import {enqueueSnack} from '../features/snacks/snacksSlice'
import {BaseVariant} from 'notistack'

const useSnack = () => {
    const dispatch = useAppDispatch()

    return (message: string, level: BaseVariant = 'default') => {
        dispatch(enqueueSnack({
            message: message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: level,
            },
        }))
    }
}

export default useSnack
