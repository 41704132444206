import React, {useEffect} from 'react';
import {useAppDispatch} from '../../../../../../hooks/hooks';
import {deleteProjectType, getProjectTypes} from '../../../../../../features/types';
import useModal from '../../../../../../hooks/useModal';
import DeleteModal from '../../../../../../components/shared/modal/delete-modal';
import {TiminatorType} from '../../../../../../features/types/types';
import MediaQuery from 'react-responsive';
import IconTextLarge from '../../../../../../components/shared/large-icon-text';
import {SVGS} from '../../../../../../assets/images';

interface Props {
    projectType: TiminatorType;
}

const DeleteProjectTypesModal = (props: Props) => {
    const dispatch = useAppDispatch();
    const {open, onToggle, openModal} = useModal();

    useEffect(() => {
        openModal();
    }, [])

    const save = () => {
        return dispatch(deleteProjectType(props.projectType.id)).unwrap().then(() => {
            onToggle();
            dispatch(getProjectTypes(0));
        })
    }

    return <>
        {open && <DeleteModal open={open} title='project type' onClose={onToggle} onConfirm={save}>
            <MediaQuery maxWidth={1000}>
                <IconTextLarge text='Delete project type'
                    subtext={`Are you sure that you want to delete "${props.projectType.name}" project type?`}
                    icon={<SVGS.DeleteIcon />} />
            </MediaQuery>
            <MediaQuery minWidth={1000}>
                Are you sure that you want to delete &quot;{props.projectType.name}&quot; project type?
            </MediaQuery>
        </DeleteModal>}
    </>
}

export default DeleteProjectTypesModal;
