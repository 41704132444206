import React, {ReactNode, useEffect} from 'react'
import useSnack from '../../../hooks/useSnack';
import BaseModal from './base-modal';

interface Props {
    children: ReactNode;
    open: boolean;
    title: string;
    onClose: () => void;
    onConfirm: () => Promise<void>;
}

export default function DeleteModal(props: Props) {
    const {open, title, onClose, onConfirm} = props;
    const snack = useSnack();

    const onDelete = async () => {
        await onConfirm();
        snack(`Deleted: ${title}`, 'success');
    }

    return <>
        {
            open && <BaseModal open={open} title={`Delete ${title}`} onClose={onClose} onConfirm={onDelete} successTitle='Delete'>
                {props.children}
            </BaseModal>
        }
    </>
}
