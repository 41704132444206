import React, { ReactNode } from 'react';
import BaseModal from './base-modal';
import useSnack from '../../../hooks/useSnack';

interface Props {
    children: ReactNode;
    open: boolean;
    title: string;
    confirmLabel: string;
    confirmDoneInfo: string;
    onClose: () => void;
    onConfirm: () => Promise<void>;
}

const InfoModal = (props: Props) => {
    const {open, title, confirmLabel, confirmDoneInfo, onClose, onConfirm} = props;
    const snack = useSnack();

    const confirm = async () => {
        await onConfirm();
        snack(confirmDoneInfo);
    }

    return <>
        {
            open && <BaseModal open={open} title={title} onClose={onClose} onConfirm={confirm} successTitle={confirmLabel}>
                {props.children}
            </BaseModal>
        }
    </>
}

export default React.memo(InfoModal)