import {Box, Stack} from '@mui/material';
import React from 'react';
import {useAppSelector} from '../../../../hooks/hooks';
import {FormattedMessage, IntlShape} from 'react-intl';
import styles from './profile.module.scss';

interface Props {
    intl: IntlShape;
}

const Profile = (props: Props) => {
    const { intl } = props;
    const employee = useAppSelector((store) => store.employee);

    return (
        <Box sx={{ marginTop: '2rem'}}>
            {
                employee.email && <>
                    <Box className={styles.banner}>
                        <Stack className={styles.contentWrapper}>
                            <div>{`${employee.firstName} ${employee.lastName}`}</div>
                            <span>{ employee.email }</span>
                        </Stack>
                        { employee.admin && <span className={styles.muted}><FormattedMessage id={'admin'} /></span> }
                    </Box>
                </>
            }
        </Box>
    )
}

export default React.memo(Profile);