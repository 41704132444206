import {AxiosInstance, AxiosResponse} from 'axios';
import {Profile} from './types';

export interface IProfileApi {
    getProfile: () => Promise<AxiosResponse<Profile>>
}

export default class ProfileApi implements IProfileApi{
    private readonly _axiosInstance;
    constructor(axiosInstance: AxiosInstance) {
        this._axiosInstance = axiosInstance
    }

    async getProfile(): Promise<AxiosResponse<Profile>> {
        return await this._axiosInstance.get('profile');
    }
}
