import React, { ReactNode, useEffect } from 'react'
import { IdNameModel } from '../../../../../../../features/settings/workcalendar/types'
import useModal from '../../../../../../../hooks/useModal'
import BaseModal from '../../../../../../../components/shared/modal/base-modal';
import { useIntl } from 'react-intl';
import { Form, Formik, useFormikContext } from 'formik';
import { FormDropdown } from '../../form-dropdown';

interface ModalProps {
    children: ReactNode;
    open: boolean;
    title: string;
    onClose: () => void;
}

const SelectModal = (props: ModalProps) => {
    const {open, title, onClose} = props;
    const { submitForm, validateForm } = useFormikContext();
    
    const onEdit = async () => {
        try {
            const hasErrors = Object.keys(await validateForm()).length !== 0;
            if (!hasErrors) {
                await submitForm();
            }
        }
        catch(error) {
            console.log(error);
        }
    }

    return <>
        {
            open && <BaseModal open={open} title={`Select ${title}`} onClose={onClose} onConfirm={onEdit} successTitle='Ok'>
                {props.children}
            </BaseModal>
        }
    </>
}

interface CalendarProps {
    calendar: IdNameModel,
    calendars: IdNameModel[],
    years: IdNameModel[],
    currentYear: number,
    onSelect: (selectedId: number, year: number) => Promise<void>
}

interface SelectionForm {
    calendar: IdNameModel,
    year: IdNameModel,
}

const SelectCalendarModal = (props: CalendarProps) => {
    const { open, onToggle, openModal } = useModal();
    const intl = useIntl();

    const initialValue: SelectionForm = {
        calendar: props.calendars.find(x => x.id == props.calendar.id)!,
        year: props.years.find(x => x.id == props.currentYear)!
    }

    const select = async (values: SelectionForm) => {
        await props.onSelect(values.calendar.id, values.year.id)
    }

    useEffect(() => {
        openModal();
    }, []);

    return <span>
        {
            open && <Formik initialValues={initialValue} onSubmit={select}>
                <Form>
                    <SelectModal open={open} title={intl.formatMessage({ id: 'workCalendar' })} onClose={onToggle} >
                        <FormDropdown required label={intl.formatMessage({ id: 'calendarName' })} name='calendar' items={props.calendars} />
                        <FormDropdown required label={intl.formatMessage({id: 'year'})} name={'year'} items={props.years} />
                    </SelectModal>
                </Form>
            </Formik>
        }
    </span>
}

export default React.memo(SelectCalendarModal)