export interface Day {
    day: number,
    isHoliday: boolean,
    isShort: boolean,
    customHours: number
}

export interface Month {
    month: number,
    days: Day[]
}

export interface Year {
    year: number,
    months: Month[]
}

export interface WorkCalendar {
    id: number,
    name: string,
    region: number,
    workHours: number,
    shortWorkHours: number,
}

export interface IdNameModel {
    id: number,
    name: string
}

export const isDay = (obj: any): obj is Day => {
    return obj != undefined && 'day' in obj
}

export const isCalendar = (obj: any): obj is WorkCalendar => {
    return obj != undefined && 'name' in obj && 'region' in obj
}

export const isNotEmptyCalendar = (obj: any): obj is WorkCalendar => {
    return obj != undefined && 'name' in obj && 'region' in obj && (obj as WorkCalendar).id > 0
}