import React, {useEffect} from 'react';
import {useAppDispatch} from '../../../../hooks/hooks';
import useModal from '../../../../hooks/useModal';
import EditModal from '../../../../components/shared/modal/edit-modal';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {FormInput} from '../../../../components/shared/form-controls/form-input';
import {FormCheckbox} from '../../../../components/shared/form-controls/form-checkbox';
import {Client} from '../../../../features/clients/types';
import {getClients, updateClient} from '../../../../features/clients';
import {filterClients} from '../../../../features/clients/filter';

interface Props {
    client: Client;
}

interface EditForm {
    name: string,
    isActive: boolean,
    contactPerson?: string,
    email?: string,
    note?: string,
    site?: string,
}

export default function EditClientModal(props: Props) {
    const {client} = props;
    const dispatch = useAppDispatch();
    const {open, onToggle, openModal} = useModal();
    const id = client.id;

    const initialValues: EditForm = {
        name: client.name,
        isActive: client.isActive,
        contactPerson: client.contactPerson,
        email: client.email,
        site: client.site,
        note: client.note,
    };

    useEffect(() => {
        openModal();
    }, [])

    const validateName = async (name: string): Promise<boolean> => {
        name = name.toLowerCase();
        if (name === '') {
            return Promise.resolve(false);
        }
        return await dispatch(filterClients(name)).unwrap().then((values) => {
            return values.length < 1 || values.some(value => value.name.toLowerCase() !== name) || name === initialValues.name.toLowerCase();
        });
    }

    const save = async (values: EditForm) => {
        const updated: Client = {
            id,
            isActive: values.isActive,
            name: values.name,
            contactPerson: values.contactPerson ?? '',
            email: values.email ?? '',
            note: values.note ?? '',
            site: values.site ?? '',
        }

        return dispatch(updateClient(updated)).unwrap().then(() => {
            dispatch(getClients(0));
            onToggle();
        })
    }

    const validationScheme: Yup.Schema<EditForm> = Yup.object({
        name: Yup.string()
            .max(45, 'Must be 45 characters or less')
            .required('Required')
            .test(
                'client-name-backend-validation',
                'client name is taken',
                async (name: string) => { return await validateName(name)}
            ),
        isActive: Yup.boolean().required(),
        contactPerson: Yup.string().optional(),
        site: Yup.string().optional(),
        email: Yup.string().email().optional(),
        note: Yup.string().optional(),
    })

    return <span>
        {open && <Formik
            initialValues={initialValues}
            validationSchema={validationScheme}
            validateOnBlur={true}
            validateOnChange={false}
            onSubmit={save}>
            <Form>
                <EditModal open={open} title='client' onClose={onToggle}>
                    <FormInput name='name' label='Name' placeholder='Name' />
                    <FormInput name='contactPerson' label='Contact person' placeholder='Person name' />
                    <FormInput name='email' label='Email' placeholder='Email' />
                    <FormInput name='site' label='Site' placeholder='Write something' />
                    <FormInput name='note' label='Note' placeholder='Write something' />
                    <fieldset>
                        <FormCheckbox name='isActive' label='Active'/>
                    </fieldset>
                </EditModal>
            </Form>
        </Formik>}
    </span>
}
