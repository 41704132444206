import { Day, IdNameModel } from '../../../../features/settings/workcalendar/types'

export const workHours = [
    12,
    11,
    10,
    9,
    8,
    7,
    6,
    5,
    4,
    3,
    2,
    1,
    0
]

export const workHoursStringArray = workHours.map(val => {
    const model: IdNameModel = {
        id: val,
        name: `${val}h`
    }

    return model
})

export interface MonthDay {
    month: number,
    day: Day
}

export const isMonthDay = (obj: any): obj is MonthDay => {
    return 'month' in obj
}