import { AxiosInstance, AxiosResponse } from 'axios';

export interface IPreferencesApi {
    getTimeZone<T extends number>(): Promise<AxiosResponse<T>>,
    getStartDayTime<T extends string>(): Promise<AxiosResponse<T>>,
    getEndDayTime<T extends string>(): Promise<AxiosResponse<T>>,

    updateTimeZone(timeZone: number): Promise<AxiosResponse<number>>,
    updateStartDayTime(time: string): Promise<AxiosResponse<string>>,
    updateEndDayTime(time: string): Promise<AxiosResponse<string>>,
}

export default class PreferencesApi implements IPreferencesApi {
    private readonly _axiosInstance;
    private readonly settingsController = 'settings';
    private readonly workcalendarController = 'workcalendar';

    constructor(axiosInstance: AxiosInstance) {
        this._axiosInstance = axiosInstance;
    }

    async getStartDayTime<T extends string>(): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this.settingsController}/start-day-time`);
    }

    async getEndDayTime<T extends string>(): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this.settingsController}/end-day-time`);
    }

    async getTimeZone<T extends number>(): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this.settingsController}/timezone`);
    }

    async updateStartDayTime(time: string): Promise<AxiosResponse<string>> {
        return await this._axiosInstance.put<string>(`${this.settingsController}/start-day-time`, JSON.stringify(time), { headers: { 'Content-Type': 'application/json' } });
    }

    async updateEndDayTime(time: string): Promise<AxiosResponse<string>> {
        return await this._axiosInstance.put<string>(`${this.settingsController}/end-day-time`, JSON.stringify(time), { headers: { 'Content-Type': 'application/json' } });
    }

    async updateTimeZone(timeZone: number): Promise<AxiosResponse<number>> {
        return await this._axiosInstance.put<number>(`${this.settingsController}/timezone`, JSON.stringify(timeZone), {headers: {'Content-Type': 'application/json'}});
    }
}