import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import HttpClient from '../../api/api';
import {VisibleDays} from './types';

type ReportState = number

const initialState: ReportState = 0

export const downloadReportByEmployee = createAsyncThunk(
    'report/download/by-employee',
    async (args: { dateFrom: string, dateTo: string, hideEmptyProjects?: boolean, employeeIds?: number[], projectIds?: number[] }) => {
        const response = await HttpClient.reportApi.downloadByEmployee<Blob>(args.dateFrom, args.dateTo, args.hideEmptyProjects, args.employeeIds, args.projectIds);
        return response.data;
    }
)

export const downloadReportByProject = createAsyncThunk(
    'report/download/by-project',
    async (args: { dateFrom: string, dateTo: string, hideEmptyProjects?: boolean, employeeIds?: number[], projectIds?: number[] }) => {
        const response = await HttpClient.reportApi.downloadByProject<Blob>(args.dateFrom, args.dateTo, args.hideEmptyProjects, args.employeeIds, args.projectIds);
        return response.data;
    }
)

export const downloadReportDetailed = createAsyncThunk(
    'report/download/detailed',
    async (args: { dateFrom: string, dateTo: string, visibleDays?: VisibleDays, employeeIds?: number[], projectIds?: number[] }) => {
        const response = await HttpClient.reportApi.downloadDetailed<Blob>(args.dateFrom, args.dateTo, args.visibleDays, args.employeeIds, args.projectIds);
        return response.data;
    }
)