import { AxiosInstance, AxiosResponse } from 'axios';

export interface INotificationsApi {
    getNotificationsEnabled<T extends boolean>(): Promise<AxiosResponse<T>>,
    updateNotificationsEnabled(enabled: boolean): Promise<AxiosResponse<boolean>>,
}

export default class NotificationsApi implements INotificationsApi {
    private readonly _axiosInstance;
    private readonly settingsController = 'settings';

    constructor(axiosInstance: AxiosInstance) {
        this._axiosInstance = axiosInstance;
    }

    async getNotificationsEnabled<T extends boolean>(): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this.settingsController}/notification-at-end-of-month`);
    }

    async updateNotificationsEnabled(enabled: boolean): Promise<AxiosResponse<boolean>> {
        return await this._axiosInstance.put<boolean>(`${this.settingsController}/notification-at-end-of-month`, JSON.stringify(enabled), {headers: {'Content-Type': 'application/json'}});
    }
}