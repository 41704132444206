import React from 'react';
import styles from './status.module.scss';

interface Props {
    color: string;
    value: string;
}

export default function Status(props: Props) {
    const {color, value} = props;
    return <span className={styles.statusWrapper} style={{display: 'inline-block'}}>
        <span className={styles.statusIcon} style={{background: color}}></span>
        <span className={styles.statusText} style={{color}}>
            {value}
        </span>
    </span>
}
