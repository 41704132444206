import { AxiosInstance, AxiosResponse } from 'axios';

export interface IIntegrationApi {
    get<T>(endpoint: string): Promise<AxiosResponse<T>>,
    enable<T>(endpoint: string): Promise<AxiosResponse<T>>,
    configure<T>(endpoint: string, configuration: T): Promise<AxiosResponse<T>>,
    disable<T>(endpoint: string): Promise<AxiosResponse<T>>
}

export default class IntegrationApi implements IIntegrationApi {
    private readonly _axiosInstance: AxiosInstance;

    constructor(axioInstance: AxiosInstance) {
        this._axiosInstance = axioInstance
    }
    
    async get<T>(endpoint: string): Promise<AxiosResponse<T, any>> {
        return await this._axiosInstance.get<T>(endpoint);
    }
    async enable<T>(endpoint: string): Promise<AxiosResponse<T, any>> {
        return await this._axiosInstance.post<T>(endpoint);
    }
    async configure<T>(endpoint: string, configuration: T): Promise<AxiosResponse<T, any>> {
        return await this._axiosInstance.post<T>(endpoint, JSON.stringify(configuration), { headers: { 'Content-Type': 'application/json' } })
    }
    async disable<T>(endpoint: string): Promise<AxiosResponse<T, any>> {
        return await this._axiosInstance.post<T>(endpoint);
    }
}