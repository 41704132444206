import React, { useEffect } from 'react'
import useModal from '../../../../../../../hooks/useModal'
import { useIntl } from 'react-intl'
import { useAppDispatch } from '../../../../../../../hooks/hooks';
import { EditForm } from '../types';
import { Formik, Form } from 'formik';
import EditModal from '../../../../../../../components/shared/modal/edit-modal';
import { FormInput } from '../../../../../../../components/shared/form-controls/form-input';
import { FormDropdown } from '../../form-dropdown';
import { IdNameModel, WorkCalendar } from '../../../../../../../features/settings/workcalendar/types';
import { workHoursStringArray } from '../../../types';
import { updateWorkCalendar } from '../../../../../../../features/settings/workcalendar';
import styles from './edit-modal.module.scss';
import { getHours, getRegion } from '../functions';

export interface Props {
    calendar: WorkCalendar,
    regions: IdNameModel[],
    onUpdate?: () => Promise<void>;
}

const EditCalendarModal = (props: Props): JSX.Element => {
    const { onUpdate } = props;
    const { open, onToggle, openModal } = useModal();
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const initialValues: EditForm = {
        name: props.calendar.name,
        region: getRegion(props.calendar.region, props.regions),
        workHours: getHours(props.calendar.workHours),
        shortworkHours: getHours(props.calendar.shortWorkHours)
    }

    const save = async (values: EditForm) => {
        const changedWorkCalendar: WorkCalendar = {
            id: props.calendar.id,
            name: values.name,
            region: values.region.id,
            workHours: values.workHours.id,
            shortWorkHours: values.shortworkHours.id
        }

        return dispatch(updateWorkCalendar({ workCalendar: changedWorkCalendar })).unwrap().then(async () => {
            onToggle();
            await onUpdate?.();
        })
    }

    useEffect(() => {
        openModal();
    }, []);

    return <span>
        {
            open && <Formik
                initialValues={initialValues}
                onSubmit={save}>
                <Form>
                    <EditModal open={open} onClose={onToggle} title={intl.formatMessage({id: 'work_calendar'})}>
                        <FormInput label={intl.formatMessage({id: 'calendarName'})} name={'name'} />
                        <fieldset>
                            <FormDropdown required label={intl.formatMessage({id: 'region'})} name={'region'} items={props.regions} />
                            <span className={styles.description}>{intl.formatMessage({id: 'edit_work_calendar_info'})}</span>
                        </fieldset>
                        <fieldset>
                            <FormDropdown required label={intl.formatMessage({id: 'workHours_in_day'})} name={'workHours'} items={workHoursStringArray} />
                            <span className={styles.description}>{intl.formatMessage({id: 'edit_work_calendar_info'})}</span>
                        </fieldset>
                        <fieldset>
                            <FormDropdown required label={intl.formatMessage({id: 'workHours_in_shortDay'})} name={'shortworkHours'} items={workHoursStringArray} />
                            <span className={styles.description}>{intl.formatMessage({id: 'edit_work_calendar_info'})}</span>
                        </fieldset>
                    </EditModal>
                </Form>
            </Formik>
        }
    </span>
}

export default React.memo(EditCalendarModal)