import { Box, Button, Stack } from '@mui/material'
import React, { ReactNode } from 'react'
import Status from '../../../../../../components/shared/status'
import styles from './integration-banner.module.scss'
import { useIntl } from 'react-intl'
import { SVGS } from '../../../../../../assets/images'
import MediaQuery from 'react-responsive'
import PopupMenuActions from '../../../../../../components/shared/popup-menu'

export enum State{
    Active,
    Awaiting,
    Disabled,
}

interface Props {
    children: ReactNode,
    name: string,
    enabled: State,
    onConnectClick: () => void,
    onConfigureClick: () => void,
    onDisconnectClick: () => void
}

const IntegrationBanner = (props: Props) => {
    const intl = useIntl()
    const header = props.name;
    const isIntegrationActive = props.enabled
    const bannerClass = `${styles.banner} ${isIntegrationActive === State.Active ? styles.active : isIntegrationActive === State.Awaiting ? styles.waiting : ''}`;
    const buttonVariant = isIntegrationActive !== State.Disabled ? 'createNew' : 'loadMore';

    const popupButtons = [
        {
            name: intl.formatMessage({ id: 'integration_configure' }),
            icon: <SVGS.EditIcon style={{ fill: '#565555' }} />,
            callback: function () { props.onConfigureClick() }
        },
        {
            name: intl.formatMessage({ id: 'integration_disconnect' }),
            icon: <SVGS.DeleteIcon />,
            callback: function () { props.onDisconnectClick() }
        }
    ]

    return (
        <Box className={bannerClass}>
            <Stack className={styles.contentWrapper}>
                <div>{header}</div>
                <span>
                    { props.children }
                </span>
            </Stack>
            <Box className={styles.status}>
                <Status value={ isIntegrationActive === State.Active ? 'Active' : isIntegrationActive === State.Awaiting ? intl.formatMessage({id: 'integration_wating_for_configuration'}) : ''} color={ isIntegrationActive === State.Active ? '#59CA59' : isIntegrationActive === State.Awaiting ? '#ffcc00' : ''} />
            </Box>
            {
                isIntegrationActive === State.Disabled ? <Button variant={buttonVariant} className={styles.actionButton} onClick={props.onConnectClick}>{intl.formatMessage({ id: 'integration_connect' })}</Button>
                    : <div className={styles.buttonWrapper}>
                        <MediaQuery minWidth={1000}>
                            <Box alignSelf={'center'}><PopupMenuActions actionIcon={<SVGS.ActionVertIcon />} actions={popupButtons} rowItem={undefined} /></Box>
                        </MediaQuery>
                        <MediaQuery maxWidth={1000}>
                            <Stack direction={'row'} spacing={1} alignItems={'stretch'} flex={'stretch'} justifyContent={'stretch'}>
                                <Button variant={buttonVariant} className={styles.actionButton} onClick={props.onConfigureClick}>{intl.formatMessage({ id: 'integration_configure' })}</Button>
                                <Button variant={buttonVariant} className={styles.actionButton} onClick={props.onDisconnectClick}>{intl.formatMessage({ id: 'integration_disconnect' })}</Button>
                            </Stack>
                        </MediaQuery>
                    </div>
            }
        </Box>
    )
}

export default React.memo(IntegrationBanner)