import {createContext} from 'react';

export interface IMomentContext {
    locale: string;
}

export const initialMomentContext: IMomentContext = {
    locale: ''
}

export const MomentContext = createContext<IMomentContext>(initialMomentContext);