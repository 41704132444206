import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import HttpClient from '../../../api/api';

export interface Notifications {
    enabled: boolean
}

const initialState: Notifications = {
    enabled: false
}

export const getNotificationsEnabled = createAsyncThunk(
    'notifications/enabled/get',
    async () => {
        const response = await HttpClient.notificationsApi.getNotificationsEnabled();
        return response.data;
    }
)

export const updateNotificationsEnabled = createAsyncThunk(
    'notifications/enabled/update',
    async (enabled: boolean) => {
        const response = await HttpClient.notificationsApi.updateNotificationsEnabled(enabled);
        return response.data;
    }
)

const typeSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        clearNotifications: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getNotificationsEnabled.fulfilled, (state, action) => {
            state.enabled = action.payload
        })
    }
})

export const { clearNotifications } = typeSlice.actions;

export default typeSlice.reducer;