import {AxiosInstance, AxiosResponse} from 'axios';

const pageSize = 50;

export interface IClientsApi {
    getAllClients<T>(): Promise<AxiosResponse<T>>,
    getClients<T>(page: number): Promise<AxiosResponse<T>>,
    filterClients<T>(filter: string): Promise<AxiosResponse<T>>,
    add<T>(body: T): Promise<AxiosResponse<T>>,
    update<T extends {id: number}>(body: T): Promise<AxiosResponse<T>>,
    delete<T extends {id: number}>(id: number): Promise<AxiosResponse<T>>,
}

export default class ClientApi implements IClientsApi {
    private readonly _axiosInstance;
    private readonly controller = 'clients';
    constructor(axiosInstance: AxiosInstance) {
        this._axiosInstance = axiosInstance
    }

    async getAllClients<T>(): Promise<AxiosResponse<T>>{
        return await this._axiosInstance.get<T>(`${this.controller}/filter`);
    }
    async getClients<T>(page: number): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this.controller}?offset=${page * pageSize}&count=${pageSize}`);
    }
    async filterClients<T>(filter: string): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this.controller}/filter?q=${filter}`);
    }
    async add<T>(body: T): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.post<T>(`${this.controller}`, JSON.stringify(body), {headers: {'Content-Type': 'application/json'}});
    }
    async update<T extends {id: number}>(body: T): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.put<T>(`${this.controller}/${body.id}`, JSON.stringify(body), {headers: {'Content-Type': 'application/json'}});
    }
    async delete<T extends {id: number}>(id: number): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.delete(`${this.controller}/${id}`);
    }
}
