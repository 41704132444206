import React, {useState} from 'react'
import styles from './month-selector.module.scss'

interface Props {
    value: any
    items: { id: number, name: string }[];
    onChange?: (value: string) => void;
}

export const MonthSelector = (props: Props) => {
    const {onChange} = props;
    const [value, setValue] = useState(props.value);

    const handleChange = (value: string) => {
        setValue(value);
        if (onChange) {
            onChange(value);
        }
    }

    return (
        <>
            <select className={styles.monthSelector} value={value} onChange={(e) => handleChange(e.target.value)}>
                {
                    props.items.map(option => <option key={option.id} value={option.id}>{option.name}</option>)
                }
            </select>
        </>);
};