import React, {useEffect} from 'react';
import {useAppDispatch} from '../../../../hooks/hooks';
import useModal from '../../../../hooks/useModal';
import DeleteModal from '../../../../components/shared/modal/delete-modal';
import MediaQuery from 'react-responsive';
import IconTextLarge from '../../../../components/shared/large-icon-text';
import {SVGS} from '../../../../assets/images';
import {deleteProject, getProjects} from '../../../../features/projects';
import { Project } from '../../../../features/projects/types';

interface Props {
    project: Project;
}

export default function DeleteProjectModal(props: Props) {
    const dispatch = useAppDispatch();
    const {open, onToggle, openModal} = useModal();

    useEffect(() => {
        openModal();
    }, [])

    const save = () => {
        return dispatch(deleteProject(props.project.id)).unwrap().then(() => {
            onToggle();
            dispatch(getProjects({projectTypeId: props.project.projectType.id, page: 0}));
        })
    }

    return <>
        {open && <DeleteModal open={open} title='project' onClose={onToggle} onConfirm={save}>
            <MediaQuery maxWidth={1000}>
                <IconTextLarge text='Delete project'
                    subtext={`Are you sure that you want to delete "${props}" project?`}
                    icon={<SVGS.DeleteIcon />} />
            </MediaQuery>
            <MediaQuery minWidth={1000}>
                Are you sure that you want to delete &quot;{props.project.name}&quot; project?
            </MediaQuery>
        </DeleteModal>}
    </>
}
