import React from 'react';
import {Box} from '@mui/material';
import styles from './subscription-banner.module.scss';
import {SubscriptionType} from './types';
import {FormattedMessage} from 'react-intl';
import {SVGS} from '../../../../assets/images';
import classNames from 'classnames';
import {Lx} from '../../../../i18n/consts';

interface SubBannerProps {
    type: SubscriptionType;
    onClick: () => Promise<void>;
    open?: boolean;
}
export const SubscriptionBannerV2: React.FC<SubBannerProps> = (props) => {
    const {type, onClick, open} = props;
    return(
        open ? <Box className={classNames({
            [styles.banner]: true,
        })}>
            {
                open && <div className={styles.container}>
                    <SVGS.LogoSkeletonIcon />
                    {
                        type === SubscriptionType.Free ? <>
                            <a onClick={() => onClick()}><h5><FormattedMessage id={Lx.Subscription.TIMINATOR_FREE}/></h5></a>
                        </>
                            : <>
                                <h5><FormattedMessage id={Lx.Subscription.TIMINATOR_PAID} /></h5>
                                <p>Active</p>
                            </>
                    }
                </div>
            }
        </Box> : <></>
    )
}