import { CalendarDate, CalendarView, InRangeResult, Month, Size, Year } from '../../types';
import moment from 'moment/moment';
import styles from './base-calendar.module.scss';
import classNames from 'classnames';
import { IconButton } from '@mui/material';
import { SVGS } from '../../../../../assets/images';
import MonthView from '../../blocks/month-view';
import React, { useState } from 'react';

const MonthV2 = (props: {
    year: number;
    month: Month;
    onSwitch: (move: 'decrease' | 'increase') => void;
    onDayClick: (month: number, day: number) => void,
    onDayOverEnter: (month: number, day: number) => void,
    onDayOverLeave: (month: number, day: number) => void,
    switchable?: 'disabled' | 'full' | 'start' | 'end',
    previewEnd?: { month: number, day: number }
    getIsDayInRange?: (month: number, day: number) => InRangeResult | undefined,
    fullFlex?: boolean
}) => {
    const dateObject = moment(`${props.year}/${props.month.id}/01`);
    const formatDate = props.switchable && props.switchable !== 'disabled' ? 'MMMM YYYY' : 'MMMM';

    const handleGetIsDayInRange = (day: number): InRangeResult | undefined => {
        return props.getIsDayInRange?.(props.month.id, day)
    }

    return (<div className={classNames({
        [styles.month]: true,
        [styles.flex]: props.fullFlex
    })}>
        <div className={classNames({
            [styles.selector]: true,
            [styles.stretch]: props.switchable && props.switchable !== 'disabled'
        })}>
            {props.switchable && props.switchable !== 'disabled' && <IconButton className={classNames({
                [styles.switchButton]: true,
                [styles.hidden]: props.switchable !== 'full' && props.switchable !== 'start'
            })}
            onClick={() => props.onSwitch('decrease')} disabled={props.switchable !== 'full' && props.switchable !== 'start'}>
                <SVGS.LeftChevronIcon />
            </IconButton>}
            <h6 className={styles.name}>{dateObject.format(formatDate)}</h6>
            {props.switchable && props.switchable !== 'disabled' && <IconButton className={classNames({
                [styles.switchButton]: true,
                [styles.hidden]: props.switchable !== 'full' && props.switchable !== 'end'
            })}
            onClick={() => props.onSwitch('increase')} disabled={props.switchable !== 'full' && props.switchable !== 'end'}>
                <SVGS.RightChevronIcon />
            </IconButton>}
        </div>
        <MonthView onDayClick={(day) => props.onDayClick(props.month.id, day)}
            onDayOverEnter={(day) => props.onDayOverEnter(props.month.id, day)}
            onDayOverLeave={(day) => props.onDayOverLeave(props.month.id, day)}
            month={props.month}
            dateObject={dateObject}
            getDayIsInRange={(day) => props.getIsDayInRange ? handleGetIsDayInRange(day) : undefined}
            range={props.previewEnd} />
    </div>)
}

interface Props {
    year: Year;
    view: CalendarView;
    size: Size;
    selectedRange?: CalendarDate[];
    previewRange?: CalendarDate[];
    onDateClick?: (year: number, month: number, day: number) => void;
    onDayOverEnter?: (year: number, month: number, day: number) => void;
    onDayOverLeave?: (year: number, month: number, day: number) => void;
    flexLayout?: boolean;
    double?: boolean;
}

const BaseCalendar: React.FC<Props> = (props) => {
    const { view, year, size, selectedRange, previewRange, onDateClick, onDayOverEnter, onDayOverLeave } = props;
    const currentDate = moment();
    const [selectedYear, setSelectedYear] = useState<number>(year.id);
    const [selectedMonth, setSelectedMonth] = useState<number>(selectedRange ? selectedRange[1].month : parseInt(currentDate.format('M')));
    const doubled = props.size === 'small' && props.double !== undefined && props.double

    const handleSwitchMonth = (move: 'decrease' | 'increase') => {
        switch (move) {
            case 'decrease':
                if (doubled && selectedMonth < 3) {
                    return;
                }
                if (selectedMonth < 2) {
                    return;
                }
                setSelectedMonth((state) => --state);
                break;
            case 'increase':
                if (selectedMonth < 12) {
                    setSelectedMonth((state) => ++state);
                }
                break;
        }
    }

    const handleDayClick = (month: number, day: number) => {
        onDateClick?.(selectedYear, month, day);
    }

    const handleDayOverEnter = (month: number, day: number) => {
        onDayOverEnter?.(selectedYear, month, day);
    }

    const handleDayOverLeave = (month: number, day: number) => {
        onDayOverLeave?.(selectedYear, month, day);
    }

    const getRangeForMonthDay = (month: number, day: number): InRangeResult | undefined => {
        if (!selectedRange && !previewRange) {
            return undefined
        }

        let start: CalendarDate;
        let end: CalendarDate
        if (previewRange) {
            start = previewRange[0]
            end = previewRange[1]
        } else if (selectedRange) {
            start = selectedRange[0];
            end = selectedRange[1]
        } else {
            return undefined;
        }

        if (end.month < 1 || end.day < 1) {
            return undefined
        }

        if ((start.month > end.month && (start.day <= end.day || start.day > end.day)) || (start.month == end.month && start.day > end.day)) {
            const temp = { ...start };
            start = {...end};
            end = temp;
        }

        if (selectedYear >= start.year && selectedYear <= end.year
            && month >= start.month && month <= end.month) {
            if (((month >= start.month && day >= start.day) || (month > start.month && day < start.day))
                && ((month <= end.month && day <= end.day) || (month < end.month && day > end.day))) {
                const range = {
                    commit: previewRange === undefined,
                    start: month === start.month && day === start.day,
                    end: month === end.month && day === end.day
                }
                return range;
            }
        }

        return undefined
    }

    return (<div className={classNames({
        [styles.container]: true,
        [styles.fullSize]: props.size === 'full'
    })}>
        {
            year && year.months.length > 0 && <>
                {
                    view == 'month' ? props.size == 'small' ? selectedMonth &&
                        <div className={classNames({
                            [styles.monthWrapper]: true,
                            [styles.wrap]: doubled
                        })}>
                            <MonthV2 month={year.months.find((m) => doubled ? m.id == selectedMonth - 1 : m.id === selectedMonth)!}
                                year={selectedYear}
                                switchable={size == 'small' ? doubled ? 'start' : 'full' : 'disabled'}
                                onSwitch={handleSwitchMonth}
                                onDayClick={handleDayClick}
                                onDayOverEnter={handleDayOverEnter}
                                onDayOverLeave={handleDayOverLeave}
                                getIsDayInRange={getRangeForMonthDay}
                                previewEnd={previewRange && { month: previewRange[1].month, day: previewRange[1].day }} />
                            {
                                doubled &&
                                <div className={styles.secondView}>
                                    <span className={styles.separatorLine} />
                                    <MonthV2 month={year.months.find((m) => m.id == selectedMonth)!}
                                        year={selectedYear}
                                        switchable={'end'}
                                        onSwitch={handleSwitchMonth}
                                        onDayClick={handleDayClick}
                                        onDayOverEnter={handleDayOverEnter}
                                        onDayOverLeave={handleDayOverLeave}
                                        getIsDayInRange={getRangeForMonthDay}
                                        previewEnd={previewRange && { month: previewRange[1].month, day: previewRange[1].day }} />
                                </div>
                            }
                        </div>
                        : <div className={classNames({
                            [styles.monthsGrid]: true,
                            [styles.flex]: props.flexLayout && props.flexLayout
                        })}>
                            {
                                year.months.map((m, index) => (<div key={index} className={classNames({
                                    [styles.monthWrapper]: true,
                                    [styles.flex]: props.flexLayout && props.flexLayout
                                })}>
                                    <MonthV2 month={m}
                                        year={selectedYear}
                                        onSwitch={() => void (0)}
                                        onDayClick={handleDayClick}
                                        onDayOverEnter={handleDayOverEnter}
                                        onDayOverLeave={handleDayOverLeave}
                                        fullFlex={props.flexLayout && props.flexLayout} />
                                </div>))
                            }
                        </div>
                        : view == 'weeks' ? <>weeks</> : <>year</>
                }
            </>
        }
    </div>)
}

export default React.memo(BaseCalendar)