import React, {ChangeEvent, HTMLAttributes} from 'react'
import {v4 as uuidv4} from 'uuid';

interface Props {
    checked: boolean;
    onChange: (value: boolean) => void;
    label: string;
    name: string;
    labelProps?: HTMLAttributes<HTMLLabelElement>
}

export default function TSwitch(props: Props) {
    const {checked, label, name, onChange} = props;

    const handleChangeDefault = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.checked)
    }

    return <label {...props.labelProps} htmlFor={name}>
        <input name={name} type='checkbox' role='switch' checked={checked} onChange={handleChangeDefault}/>
        {label}
    </label>
}
