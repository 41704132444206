import {configureAxios} from './axios'
import {initFakeAxios} from './fakeAxios'
import {AxiosInstance} from 'axios'
import AuthAPI, {IAuthAPI} from '../features/auth/authAPI';
import ProfileApi, {IProfileApi} from '../features/profile/api';
import TypeApi, {ITypeApi} from '../features/types/api';
import EmployeeApi, {IEmployeesApi} from '../features/employees/api';
import RoleApi, {IRolesApi} from '../features/roles/api';
import ClientApi, {IClientsApi} from '../features/clients/api';
import ProjectApi, {IProjectsApi} from '../features/projects/api';
import PermissionApi, {IPermissionApi} from '../features/permissions/api';
import PreferencesApi, {IPreferencesApi} from '../features/settings/preferences/api';
import NotificationsApi, {INotificationsApi} from '../features/settings/notifications/api';
import SettingsIntegrationApi, { ISettingsIntegrationApi } from '../features/settings/integrations/api';
import EarlyAccessApi, { IEarlyAccessApi } from '../features/settings/early-access/api';
import WorkCalendarApi, { IWorkCalendarApi } from '../features/settings/workcalendar/api';
import PaymentsApi, {IPaymentsApi} from '../features/payments/api';
import SubscriptionApi, {ISubscriptionApi} from '../features/billing/subscription/api';
import IntegrationApi, { IIntegrationApi } from '../features/integrations/api';
import AbsencesApi, {IAbsencesApi} from '../features/absences/api';
import ShiftsApi, {IShiftsApi} from '../features/shifts/api';
import ReportApi, {IReportApi} from '../features/report/api';

export interface IHttpClient {
    authApi: IAuthAPI;
    profileApi: IProfileApi;
    typeApi: ITypeApi;
    employeeApi: IEmployeesApi;
    roleApi: IRolesApi;
    clientApi: IClientsApi;
    projectApi: IProjectsApi;
    permissionApi: IPermissionApi;
    preferencesApi: IPreferencesApi;
    workcaledarApi: IWorkCalendarApi;
    paymentsApi: IPaymentsApi;
    subscriptionApi: ISubscriptionApi;
    absencesApi: IAbsencesApi;
    shiftsApi: IShiftsApi;
}

class HttpClient implements IHttpClient {
    private readonly _axiosInstance: AxiosInstance;
    private readonly _obsoleteAxiosInstance: AxiosInstance;
    private static _instance: HttpClient;
    public authApi: IAuthAPI;
    public profileApi: IProfileApi;
    public typeApi: ITypeApi;
    public employeeApi: IEmployeesApi;
    public roleApi: IRolesApi;
    public clientApi: IClientsApi;
    public projectApi: IProjectsApi;
    public permissionApi: IPermissionApi;
    public preferencesApi: IPreferencesApi;
    public notificationsApi: INotificationsApi;
    public settingsIntegrationApi: ISettingsIntegrationApi;
    public earlyAccessApi: IEarlyAccessApi;
    public workcaledarApi: IWorkCalendarApi;
    public subscriptionApi: ISubscriptionApi;
    public integrationsApi: IIntegrationApi;
    public paymentsApi: IPaymentsApi;
    public absencesApi: IAbsencesApi;
    public shiftsApi: IShiftsApi;
    public reportApi: IReportApi;

    constructor() {
        this._axiosInstance = configureAxios()
        this._obsoleteAxiosInstance = configureAxios(true)

        if(process.env.REACT_APP_IS_MOCK_AXIOS == 'true') {
            initFakeAxios(this._axiosInstance)
        }
        this.authApi = new AuthAPI(this._axiosInstance)
        this.profileApi = new ProfileApi(this._axiosInstance)
        this.typeApi = new TypeApi(this._axiosInstance)
        this.employeeApi = new EmployeeApi(this._axiosInstance)
        this.roleApi = new RoleApi(this._axiosInstance)
        this.clientApi = new ClientApi(this._axiosInstance)
        this.projectApi = new ProjectApi(this._axiosInstance)
        this.permissionApi = new PermissionApi(this._axiosInstance);
        this.preferencesApi = new PreferencesApi(this._axiosInstance)
        this.notificationsApi = new NotificationsApi(this._axiosInstance)
        this.settingsIntegrationApi = new SettingsIntegrationApi(this._axiosInstance)
        this.earlyAccessApi = new EarlyAccessApi(this._axiosInstance)
        this.workcaledarApi = new WorkCalendarApi(this._axiosInstance)
        this.absencesApi = new AbsencesApi(this._axiosInstance);
        this.shiftsApi = new ShiftsApi(this._axiosInstance);
        this.reportApi = new ReportApi(this._axiosInstance);

        //OBSOLETE API
        this.integrationsApi = new IntegrationApi(this._obsoleteAxiosInstance)
        this.paymentsApi = new PaymentsApi(this._obsoleteAxiosInstance)
        this.subscriptionApi = new SubscriptionApi(this._obsoleteAxiosInstance)
    }

    public static get Instance() {
        return this._instance || (this._instance = new this())
    }
}

export default HttpClient.Instance
