import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import HttpClient from '../../../api/api';
import { Integration, Integrations } from './types';

const initialState: Integrations = {
    items: []
}

export const getIntegrations = createAsyncThunk(
    'integrations/get',
    async () => {
        const response = await HttpClient.settingsIntegrationApi.getIntegrations<Integration[]>();
        return response.data;
    }
)

const typeSplice = createSlice({
    name: 'integrations',
    initialState,
    reducers: {
        clearIntegrations: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getIntegrations.fulfilled, (state, action) => {
            state.items = action.payload;
        });
    }
});

export const { clearIntegrations } = typeSplice.actions;

export default typeSplice.reducer;