import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import HttpClient from '../../api/api';
import {PaymentResult, PaymentState, SetPaymentMethod} from './types';

interface PaymentsState {
    paymentState: PaymentState;
    setPaymentMethodUrl: SetPaymentMethod;
    paymentResult: PaymentResult;
}

const initialState: PaymentsState = {
    paymentState: {
        employeeName: '',
        paymentMethodAddedByCurrentEmployee: false,
        canAddPaymentMethod: false,
        canDoPayment: false,
        canRemovePaymentMethod: false
    },
    setPaymentMethodUrl: {
        url: ''
    },
    paymentResult: {
        error: false,
        errorMessage: '',
        success: false,
        needThreeDSecure: false,
        threeDSecureUrl: ''
    }
}

export const getPaymentState = createAsyncThunk(
    'payments/state/get',
    async () => {
        const response = await HttpClient.paymentsApi.getState<PaymentState>();
        return response.data;
    }
)

export const setPaymentMethod = createAsyncThunk(
    'payments/set',
    async (callback: string) => {
        const response = await HttpClient.paymentsApi.setPaymentMethod<string>(callback);
        return response.data;
    }
)

export const removePaymentMethod = createAsyncThunk(
    'payments/remove',
    async () => {
        const response = await HttpClient.paymentsApi.removePaymentMethod();
        return response.data;
    }
)

export const doPayment = createAsyncThunk(
    'payments/do-payment',
    async (callback: string) => {
        const response = await HttpClient.paymentsApi.doPayment<PaymentResult>(callback);
        return response.data;
    }
)

const paymentSlice = createSlice({
    name: 'payments',
    initialState,
    reducers: {
        clearPaymentState: (state) => {
            state.paymentState = initialState.paymentState;
        },
        clearPaymentResult: (state) => {
            state.paymentResult = initialState.paymentResult;
        },
        clearSetPaymentMethod: (state) => {
            state.setPaymentMethodUrl = initialState.setPaymentMethodUrl;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPaymentState.fulfilled, (state, action) => {
            state.paymentState = action.payload
        })
        builder.addCase(setPaymentMethod.fulfilled, (state, action) => {
            state.setPaymentMethodUrl = { url: action.payload }
        })
        builder.addCase(doPayment.fulfilled, (state, action) => {
            state.paymentResult = action.payload
        })
    },
})

export const { clearPaymentState, clearPaymentResult } = paymentSlice.actions;

export default paymentSlice.reducer;
