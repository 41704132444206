import React, {useState} from 'react'
import PageWrapper from '../../components/shared/page-wrapper';
import {Box, Tab, Tabs} from '@mui/material';
import TabPanel from '../../components/shared/tab-panel';
import ProjectTypes from './components/project-types';
import ShiftTypes from './components/shift-types';
import AbsencesTypes from './components/absences-types';
import {useAppSelector} from '../../hooks/hooks';
import {Part} from '../../features/profile/types';
import {isVisible} from '../../helpers/profileHelper';
import {FormattedMessage, useIntl} from 'react-intl';

export default function TypesPage() {
    const intl = useIntl();
    const profile = useAppSelector(store => store.profile);
    const [value, setValue] = useState<number>(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }

    return  <PageWrapper>
        <h3><FormattedMessage id={'types'} /></h3>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} scrollButtons="auto" variant='scrollable'>
                {isVisible(profile, Part.ProjectTypes) && <Tab disableRipple={true} label={intl.formatMessage({id: 'project_types'})} />}
                {isVisible(profile, Part.ShiftTypes) && <Tab disableRipple={true} label={intl.formatMessage({id: 'shift_types'})} />}
                {isVisible(profile, Part.AbsenceTypes) && <Tab disableRipple={true} label={intl.formatMessage({id: 'absences_types'})} />}
            </Tabs>
        </Box>
        {isVisible(profile, Part.ProjectTypes) && <TabPanel value={value} index={0}>
            <ProjectTypes />
        </TabPanel>}
        {isVisible(profile, Part.ShiftTypes) && <TabPanel value={value} index={1}>
            <ShiftTypes />
        </TabPanel>}
        {isVisible(profile, Part.AbsenceTypes) && <TabPanel value={value} index={2}>
            <AbsencesTypes />
        </TabPanel>}
    </PageWrapper>
};
