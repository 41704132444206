import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import HttpClient from '../../../api/api';

export interface EarlyAccess {
    enabled: boolean
}

const initialState: EarlyAccess = {
    enabled: false
}

export const getEarlyAccessEnabled = createAsyncThunk(
    'early-access/enabled/get',
    async () => {
        const response = await HttpClient.earlyAccessApi.getEarlyAccessEnabled();
        return response.data;
    }
)

export const updateEarlyAccessEnabled = createAsyncThunk(
    'early-access/enabled/update',
    async (enabled: boolean) => {
        const response = await HttpClient.earlyAccessApi.updateEarlyAccessEnabled(enabled);
        return response.data;
    }
)

const typeSlice = createSlice({
    name: 'early-access',
    initialState,
    reducers: {
        clearEarlyAccess: () => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getEarlyAccessEnabled.fulfilled, (state, action) => {
            state.enabled = action.payload
        })
    }
})

export const { clearEarlyAccess } = typeSlice.actions;

export default typeSlice.reducer;

