import styles from './timeshift-status-selector.module.scss'
import {Button, styled} from '@mui/material';

export default styled(Button)((theme) => ({
    boxShadow: 'none',
    padding: 4,
    margin: 0,
    borderWidth: 0,
    borderRadius: 4,
    height: '100%',
    width: 'auto',
    minWidth: 0,
    border: '0.1px solid transparent',
    color: styles.primary,
    background: styles.default,

    '&.MuiButton-root': {
        '&.active': {
            border: `0.1px solid ${styles.primary}`,
            background: styles.secondary,
            svg: {
                path: {
                    fill: styles.primary
                }
            }
        }
    },

    '&.active': {
        background: styles.secondary,
    },

    '&:hover': {
        background: styles.default,
        svg: {
            path: {
                fill: styles.primary
            }
        }
    }
}))
