import React, {useEffect} from 'react';
import {Day} from '../../../../../../../features/settings/workcalendar/types';
import {useAppDispatch} from '../../../../../../../hooks/hooks';
import useModal from '../../../../../../../hooks/useModal';
import * as Yup from 'yup';
import {Form, Formik} from 'formik';
import {updateDay} from '../../../../../../../features/settings/workcalendar';
import { IdNameModel } from '../../../../../../../features/settings/workcalendar/types';
import EditModal from '../../../../../../../components/shared/modal/edit-modal';
import moment from 'moment';
import {FormDropdown} from '../../../../../../../components/shared/form-controls/form-dropdown';
import { FormDropdown as CalendarFormDropdown } from '../../form-dropdown'
import styles from './edit-model.module.scss';
import {EditForm, Props, DayType} from './types'
import {useIntl} from 'react-intl';
import { workHoursStringArray } from '../../../types';

const dayTypes: IdNameModel[] = [
    { id: DayType.IsWorkingDay, name: 'Is working day' },
    { id: DayType.IsShortDay, name: 'Is short day' },
    { id: DayType.IsDayOff, name: 'Is day off' }
]

const validationScheme: Yup.Schema<EditForm> = Yup.object({
    dayType: Yup.object<IdNameModel>().shape({
        id: Yup.number().required(),
        name: Yup.string().required()
    }).required(),
    customHours: Yup.object().shape({
        id: Yup.number().required(),
        name: Yup.string().optional()
    }).optional()
})

const EditMonthDayModal = (props: Props) => {
    const {workCalendarId, year, month, day, onUpdate} = props;
    const dispatch = useAppDispatch();
    const {open, onToggle, openModal} = useModal();
    const changingDate = moment(`${year}/${month}/${day.day}`);
    const intl = useIntl()

    const getDayType = (day: Day): IdNameModel => {
        if (day.isShort) {
            return dayTypes.find(x => x.id === DayType.IsShortDay)!;
        } else if (day.isHoliday) {
            return dayTypes.find(x => x.id === DayType.IsDayOff)!;
        }

        return dayTypes.find(x => x.id === DayType.IsWorkingDay)!;
    }

    const getHours = (hour: number): IdNameModel => {
        return workHoursStringArray.find(x => x.id == hour)! ?? { id: 0, name: '' };
    }

    const initialValues: EditForm = {
        dayType: getDayType(day),
        customHours: getHours(day.customHours)
    }

    useEffect(() => {
        openModal();
    }, [])

    const save = async (values: EditForm) => {
        const changedDay: Day = {
            day: day.day,
            customHours: values.dayType.id == DayType.IsDayOff ? 0 : values.customHours?.id ?? 0,
            isShort: values.dayType.id == DayType.IsShortDay,
            isHoliday: values.dayType.id == DayType.IsDayOff
        }

        return await dispatch(updateDay({ workCalendarId, year, month, day: changedDay })).unwrap().then(async () => {
            onToggle();
            await onUpdate();
        })
    }

    return <span>
        {
            open && <Formik
                initialValues={initialValues}
                validationSchema={validationScheme}
                onSubmit={save}>
                <Form>
                    <EditModal open={open} onClose={onToggle} title={intl.formatMessage({id: 'date_settings'}, { s: changingDate.format('MMMM Do, YYYY')})}>
                        <FormDropdown required={true} label={intl.formatMessage({id: 'day_type'})} name='dayType' items={dayTypes}/>
                        <fieldset>
                            <CalendarFormDropdown label={intl.formatMessage({id: 'work_hours'})} placeholder='Pick an option' name='customHours' items={workHoursStringArray}/>
                            <span className={styles.hoursDescription}>{intl.formatMessage({id: 'empty_field_ability'})}</span>
                        </fieldset>
                    </EditModal>
                </Form>
            </Formik>
        }
    </span>
}

export default React.memo(EditMonthDayModal)