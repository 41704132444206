import { SVGS } from '../assets/images'
import {IAppRouter} from './interfaces/IAppRouter'

const ROUTES: IAppRouter = {
    main: '/',
    login: '/auth',
    linkProceed: '/user/email-magic-link',
    logout: {
        name: 'Logout',
        url: '/logout',
    },
    types: {
        name: 'types',
        url: '/types',
        // eslint-disable-next-line react/react-in-jsx-scope
        svg: <SVGS.TypesIcon />,
    },
    roles: {
        name: 'roles',
        url: '/roles',
        // eslint-disable-next-line react/react-in-jsx-scope
        svg: <SVGS.RolesIcon />,
    },
    employees: {
        name: 'employees',
        url: '/employees',
        // eslint-disable-next-line react/react-in-jsx-scope
        svg: <SVGS.EmployeesIcon />,
    },
    clients: {
        name: 'clients',
        url: '/clients',
        // eslint-disable-next-line react/react-in-jsx-scope
        svg: <SVGS.ClientsIcon />,
    },
    projects: {
        name: 'projects',
        url: '/projects',
        // eslint-disable-next-line react/react-in-jsx-scope
        svg: <SVGS.ProjectsIcon />,
    },
    settings: {
        name: 'settings',
        url: '/settings',
        // eslint-disable-next-line react/react-in-jsx-scope
        svg: <SVGS.SettingsIcon />,
    },
    manage: {
        name: 'manage',
        url: '/',
        // eslint-disable-next-line react/react-in-jsx-scope
        svg: <SVGS.ProfileIcon />,
    },
    schedule: {
        name: 'schedule',
        url: '/schedule',
        // eslint-disable-next-line react/react-in-jsx-scope
        svg: <SVGS.AddChartIcon />,
    },
    zoho: '/zoho',
}

export default ROUTES
