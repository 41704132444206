import { createTheme } from '@mui/material/styles'

const theme = createTheme({
    components: {
        MuiAccordion: {
            styleOverrides:{
                root:{
                    color: 'var(--color)',
                    backgroundColor: '#181818',
                    '&:focus': {
                        boxShadow: 'none',
                    },
                    '&.MuiButtonBase':{
                        boxShadow: 'none',
                    },
                    boxShadow: 'none',
                    '&::before':{
                        backgroundColor:'#565555',
                        opacity:0.6
                    }
                }
            }
        },
        MuiAccordionDetails:{
            styleOverrides:{
                root:{
                    padding:'0',
                    color: '#565555'
                }
            }
        },
        MuiAccordionSummary: {
            styleOverrides:{
                root:{
                    padding: '0',
                    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                        transform: 'rotate(90deg)'
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: '12px 16px',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    textTransform: 'none',
                    lineHeight: '19px',
                    color: '#FFFFFF',
                },
            },
            variants: [
                {
                    props: { variant: 'loadMore' },
                    style: {
                        color: '#FFFFFF',
                        background: '#F26D6B',
                        borderRadius: '8px',
                        width: '100%',
                        padding: '0.6rem 1.2rem',
                        gap: '0.4rem',
                        fontSize: '1rem',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '150%',
                        boxShadow: 'none',
                        ':hover': {
                            background: '#AC4D4C'
                        }
                    }
                },
                {
                    props: { variant: 'black' },
                    style: {
                        color: '#FFFFFF',
                        background: '#000',
                        border: '1px solid black',
                        borderRadius: '4px',
                        ':hover': {
                            background: '#828282'
                        }
                    }
                },
                {
                    props: { variant: 'white' },
                    style: {
                        color: '#000',
                        background: '#FFFFFF',
                        border: '1px solid black',
                        borderRadius: '4px',
                        ':hover': {
                            background: '#828282',
                            color: '#FFFFFF'
                        }
                    }
                },
                {
                    props: { variant: 'overviewHeader'},
                    style: {
                        width: 'max-content',
                        padding: '12px 16px',
                        border: '1px solid #E0E0E0',
                        boxSizing: 'border-box',
                        borderRadius: '4px'
                    }
                },
                {
                    props: { variant: 'input' },
                    style: {
                        margin: '0px',
                        padding: '0px',
                        minWidth: '30px'
                    }
                },
                {
                    props: { variant: 'createNew' },
                    style: {
                        color: '#FFFFFF',
                        background: '#1F1F1F',
                        borderRadius: '8px',
                        width: '100%',
                        fontSize: '1rem',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '150%',
                        boxShadow: 'none',
                        ':hover': {
                            background: '#181818'
                        }
                    }
                },
                {
                    props: { variant: 'scheduleAction' },
                    style: {
                        color: '#BEBDBD',
                        background: '#282727',
                        borderRadius: '8px',
                        width: 'auto',
                        boxShadow: 'none',
                        padding: '0.4rem 0.8rem',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '0.65rem;',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '150%',
                        ':hover': {
                            background: '#2C2B2B'
                        },
                        ':disabled': {
                            color: '#727171'
                        }
                    }
                }
            ]
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    color: '#FFFFFF',
                },
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    background: '#F26D6B',
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: '#FFFFFF',
                    textTransform: 'none',
                    maxWidth: 200,
                    width: 'auto',
                    padding: '16px 24px',
                    '&.Mui-selected': {
                        color: '#F26D6B'
                    },
                    '&:focus': {
                        boxShadow: 'none',
                        background: 'none'
                    },
                    '&:hover': {
                        background: 'none',
                        color: '#F26D6B'
                    }
                },
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    background: '#1F1F1F',
                    color: '#565555',
                    border: 'none',
                    marginLeft: '0.8rem'
                }
            }
        },
        MuiTypography: {
            variants: [
                {
                    props: { variant: 'pageHeader'},
                    style: {
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap',
                    }
                },
                {
                    props: { variant: 'pageHeaderNumber'},
                    style: {
                        fontSize: '1.5rem',
                        fontWeight: '500',
                        marginLeft: '16px',
                        color: '#828282'
                    }
                },
                {
                    props: { variant: 'dataCounter'},
                    style: {
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        color: '#828282',
                        minWidth: '40px',
                        textAlign: 'center'
                    }
                },
                {
                    props: { variant: 'overviewHeader'},
                    style: {
                        fontSize: '1.25rem',
                        fontWeight: 'bold',
                        color: '#333333',
                        letterSpacing: '-0.015em',
                        display: 'flex',
                        alignItems: 'center',
                    }
                },
                {
                    props: { variant: 'catalogItem'},
                    style: {
                        fontSize: '1rem',
                        fontWeight: 'normal',
                        color: '#000',
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        padding: '10px 24px',
                        '&.active': {
                            background: '#FFFFFF',
                            fontWeight: 'bold'
                        },
                        ':hover': {
                            background: '#FFFFD4',
                            fontWeight: 'bold'
                        }
                    }
                }
            ]
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    background: '#121212',
                    color: '#FFFFFF',
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    background: '#121212',
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: '#F26D6B'
                }
            }
        }
    },
    palette: {
        primary: {
            main: '#1d1d1d'
        },
        divider: '#1F1F1F',
    },
})

export default theme
