import {Box} from '@mui/system';
import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {SVGS} from '../../../../assets/images';
import IconTextLarge from '../../../../components/shared/large-icon-text';
import {getPaymentState, setPaymentMethod} from '../../../../features/payments';
import {Button, Chip, Stack} from '@mui/material';
import styles from './payments.module.scss';
import {Column} from '../../../../components/shared/table/types';
import {store} from '../../../../store/store';
import {selectIdOnDelete} from '../../../../features/modals';
import DeletePaymentMethodModal from './components/delete-modal';
import {FormattedMessage, IntlShape, useIntl} from 'react-intl';
import {PopupMenuAction} from '../../../../components/shared/popup-menu/types';
import TableActions from '../../../../components/shared/table/components/table-actions';
import {showSnack} from '../../../../features/snacks/snackService';

interface PaymentMethod {
    name: string
}

const columns: Column[] = [
    {
        id: 'name',
        label: '',
        format: (rowData: PaymentMethod) => {
            return <span>
                <>
                    <div>{rowData.name}</div>
                </>
            </span>
        }
    }
];

const actions: PopupMenuAction[] = [
    {
        name: 'Delete',
        icon: <SVGS.DeleteIcon/>,
        callback: function () {
            store.dispatch(selectIdOnDelete({id: 1}))
        },
    }
]

export default function Payments(props: { intl: IntlShape }) {
    const { intl } = props;
    const dispatch = useAppDispatch();
    const paymentState = useAppSelector(store => store.payments.paymentState);
    const selectedDelete = useAppSelector(store => store.modals.selectedDelete);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        dispatch(getPaymentState()).then(() => {
            setLoading(false);
        }).catch((e) => {
            console.error(e)
        })
    }, [])

    const loadSetPaymentMethodUrl = () => {
        const currentLocation = window.location.href;
        return dispatch(setPaymentMethod(currentLocation));
    }

    const handleAddPaymentMethodClick = async () => {
        if (!paymentState.canAddPaymentMethod) {
            return
        }
        try {
            loadSetPaymentMethodUrl().unwrap().then((result) => {
                if (result !== '') {
                    window.location.replace(result);
                }
            });
        } catch (e) {
            showSnack('Add payment method is not supported yet', 'info');
            console.error(e);
        }
    }

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        marginTop: '2rem'
    }}>
        {!loading && <Box>
            {
                paymentState && !paymentState.canAddPaymentMethod && paymentState.canDoPayment
                    ? <Box>
                        <Box className={styles.paymentMethod}>
                            <div className={styles.nameWrapper}>
                                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                    <SVGS.StripeIcon className={styles.methodIcon} />
                                    <Box alignItems={'center'}>
                                        <FormattedMessage id={'paymentMethod_setup'} />
                                        {
                                            paymentState.paymentMethodAddedByCurrentEmployee
                                            && <Chip
                                                label={<FormattedMessage id={'me'} />}/>
                                        }
                                        {
                                            !paymentState.paymentMethodAddedByCurrentEmployee &&
                                            <Chip label={paymentState.employeeName}/>
                                        }
                                    </Box>
                                </Stack>
                            </div>
                            {
                                paymentState.paymentMethodAddedByCurrentEmployee
                                && <div className={styles.actionIconWrapper}>
                                    <TableActions actions={actions} rowItem={null}/>
                                </div>
                            }
                        </Box>
                    </Box>
                    : <Box className={styles.createPaymentWrapper}>
                        <IconTextLarge
                            text={intl.formatMessage({id: 'you_have_no_payment_method_added'})}
                            subtext={intl.formatMessage({id: 'you_have_no_payment_method_added_desc'})}
                            icon={<SVGS.CreditCardIcon/>}/>
                        <Button
                            variant='loadMore' className={styles.actionButton}
                            onClick={handleAddPaymentMethodClick} disabled={!paymentState.canAddPaymentMethod}>{intl.formatMessage({id: 'add_payment_method'})}</Button>
                    </Box>
            }
        </Box>}
        {selectedDelete && <DeletePaymentMethodModal/>}
    </Box>
}