import {AxiosInstance, AxiosResponse} from 'axios';

const pageSize = 50;

export interface IEmployeesApi {
    getAllEmployees<T>(): Promise<AxiosResponse<T>>,
    getEmployees<T>(page: number): Promise<AxiosResponse<T>>,
    getEmployee<T>(id: number): Promise<AxiosResponse<T>>,
    add<T>(body: T): Promise<AxiosResponse<T>>,
    update<T extends {id: number}>(body: T): Promise<AxiosResponse<T>>,
    delete<T extends {id: number}>(id: number): Promise<AxiosResponse<any>>,
}

export default class EmployeeApi implements IEmployeesApi {
    private readonly _axiosInstance;
    private readonly controller = 'employees';
    constructor(axiosInstance: AxiosInstance) {
        this._axiosInstance = axiosInstance
    }
    async getAllEmployees<T>(): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this.controller}/filter`);
    }
    async getEmployees<T>(page: number): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this.controller}?offset=${page * pageSize}&count=${pageSize}`);
    }
    async getEmployee<T>(id: number): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this.controller}/${id}`);
    }
    async add<T>(body: T): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.post<T>(`${this.controller}`, JSON.stringify(body), {headers: {'Content-Type': 'application/json'}});
    }
    async update<T extends {id: number}>(body: T): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.put<T>(`${this.controller}/${body.id}`, JSON.stringify(body), {headers: {'Content-Type': 'application/json'}});
    }
    async delete<T extends {id: number}>(id: number): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.delete(`${this.controller}/${id}`);
    }
}
