import {GridColumnType} from '../../types';
import classNames from 'classnames';
import styles from '../../grid.module.scss';
import React, {HTMLAttributes, ReactElement, useEffect, useRef, useState} from 'react';
import moment from 'moment';
import {SVGS} from '../../../../../assets/images';
import { ClickAwayListener } from '@mui/material';

const formatDateCell = (value: string): string => {
    return moment(value).format('L')
}

const FormatCell = (props: { value: string, hover: boolean, editable?: boolean, type?: GridColumnType }): React.ReactElement => {
    const { value, hover, type, editable } = props;
    if (!type) {
        return (<>{value}</>)
    }

    switch (type) {
        case 'date':
            return (<>{formatDateCell(value)}</>)
        case 'singleSelect':
            return (<span className={classNames({
                [styles.dropdownCell]: true,
                [styles.editable]: editable
            })}>{value}<SVGS.DropdownIcon /></span>)
        case 'text':
            return (<span className={styles.cellText}>
                {value}
            </span>)
        default:
            return (<span className={styles.value}>{value}</span>);
    }
}

interface Props {
    flex: boolean;
    muted: boolean;
    editable: boolean;
    type: GridColumnType, width: number | string;
    children: any;
    active: boolean;
    onOutsideClick: () => void;
    cellProps: React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
}

const Cell = (props: Props) => {
    const { active, children, onOutsideClick, cellProps } = props;
    const [hover, setHover] = React.useState(false);
    const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
        setHover(true)
        cellProps.onMouseEnter?.(event)
    };

    const handleMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
        setHover(false)
        cellProps.onMouseLeave?.(event)
    };

    return(<ClickAwayListener onClickAway={() => onOutsideClick()}>
        <div className={classNames({
            [styles.cell]: true,
            [styles.flex]: props.flex,
            [styles.muted]: props.muted,
            [styles.editable]: props.editable,
            [styles.text]: props.type == 'string' || props.type === 'text',
            [styles.active]: props.active
        })}
        style={{width: props.width}}
        {...cellProps}
        onMouseEnter={(event) => handleMouseEnter(event)}
        onMouseLeave={(event) => handleMouseLeave(event)}>
            {
                active ? children :
                    children && <FormatCell value={children} type={props.type} editable={props.editable}
                        hover={hover}/>
            }
        </div>
    </ClickAwayListener>)
}

export default Cell