export interface ZohoConfiguration {
    organizationId: string,
    synchronizationType: number,
    employeesForSynchronization: Array<number>,
    employeesForNotification: Array<number>,
    projectsForSynchronization: Array<number>
}

export interface ZohoIntegration {
    enabled: boolean,
    configuration: ZohoConfiguration,
    availableOrganizations: Array<SIdNameModel>,
    availableEmployeesForNotifications: Array<IdNameModel>,
    availableEmployeesForSynchronization: Array<IdNameModel>,
    availableProjects: Array<IdNameModel>
}

export interface IdNameModel {
    id: number,
    name: string,
    isDefault: boolean
}

export interface SIdNameModel {
    id: string,
    name: string
}

export const isZohoIntegration = (obj: any): obj is ZohoIntegration => {
    return obj !== undefined && 'configuration' in obj;
}