import React, {useLayoutEffect, useEffect, useState, useMemo, useRef} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Box, Skeleton, TextField, useMediaQuery} from '@mui/material';
import {useSnackbar} from 'notistack';
import TableData from './types';
import TableActions from './components/table-actions';
import styles from './table.module.scss';
import classNames from 'classnames';

interface Props {
    tableSettings: TableData;
    fetchData: (pageNumber: number, searchString?: string) => Promise<void>;
    onRowClick?: (id: any) => void;
    searchable?: boolean;
    offset?: number;
}

export default function TiminatorTable(props: Props) {
    const {tableSettings, fetchData, onRowClick, offset = 0, searchable} = props;
    const [page, setPage] = useState<number>(0);
    const [searchText, setSearchText] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(true);
    const containerRef = useRef<null | HTMLDivElement>(null);
    const isDesktop = useMediaQuery('(min-width:1000px)')

    const { enqueueSnackbar } = useSnackbar();
    const clickable = !!onRowClick;
    const full = useMemo(() => {
        if (!tableSettings.data) {
            return false;
        }
        return tableSettings.totalRows <= tableSettings.data?.length;
    }, [tableSettings])

    useEffect(() => {
        setPage(offset/50);
    }, [offset])

    const handleChangePage = async (pageNumber: number) => {
        try {
            setPage(pageNumber);
            setLoading(true)
            await fetchData(pageNumber, searchText);
            setLoading(false);
        }
        catch {
            enqueueSnackbar('Something went wrong with fetching data');
        }
    };

    useEffect(() => {
        setPage(0);
        setLoading(true)
        fetchData(0, searchText).then(() => {
            if (containerRef.current) {
                containerRef.current.scrollTop = 0;
            }
            setLoading(false)
        }).catch(() => {
            enqueueSnackbar('Something went wrong with fetching data');
        });
    }, [searchText]);

    const handleScroll = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom && !full) {
            handleChangePage(page + 1)
        }
    }

    return (
        <Box sx={{width: '100%', overflow: 'hidden'}}>
            {searchable && <TextField label="Search"
                variant="outlined"
                sx={{margin: '10px 25px'}}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}/>}
            <TableContainer sx={{maxHeight: '100%'}} onScroll={handleScroll} ref={containerRef}>
                <Table aria-label="table" className={styles.table} sx={{borderSpacing: '0 1rem !important', borderCollapse: 'separate !important'}}>
                    <TableHead>
                        <TableRow>
                            {tableSettings.columns?.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{minWidth: column.minWidth, maxWidth: column.maxWidth, display: column.isStatus && !isDesktop ? 'none' : 'table-cell'}}
                                    sx={{color: 'var(--table-header-color)', border: 'none'}}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                            {tableSettings.actions && <TableCell align="right" sx={{border: 'none'}} />}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? <>
                            <TableRow className={styles.tableRow}>
                                <TableCell
                                    colSpan={tableSettings.columns?.length}
                                    sx={{border: 'none', padding: 0}}
                                >
                                    <Skeleton variant='rectangular' height={50}/>
                                </TableCell>
                            </TableRow>
                            <TableRow className={styles.tableRow}>
                                <TableCell
                                    colSpan={tableSettings.columns?.length}
                                    sx={{border: 'none', padding: 0}}
                                >
                                    <Skeleton variant='rectangular' height={50}/>
                                </TableCell>
                            </TableRow>
                            <TableRow className={styles.tableRow}>
                                <TableCell
                                    colSpan={tableSettings.columns?.length}
                                    sx={{border: 'none', padding: 0}}
                                >
                                    <Skeleton variant='rectangular' height={50}/>
                                </TableCell>
                            </TableRow>
                        </> : tableSettings.data
                            ?.map((item: any) => {
                                return (
                                    <TableRow role="checkbox" tabIndex={-1} key={item.id}
                                        sx={{ cursor: clickable ? 'pointer' : 'default'}}
                                        className={styles.tableRow}
                                        onClick={clickable ? (e) => onRowClick(item.id) : undefined}>
                                        {tableSettings.columns.map((column) => {
                                            const value = item[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}
                                                    className={styles.tableCell}
                                                    style={{display: column.isStatus && !isDesktop ? 'none' : 'table-cell',
                                                        borderColor: item.active || item.isActive ? '#59CA59' : '#F26D6B'}}
                                                    sx={{minWidth: column.minWidth, maxWidth: column.maxWidth, border: 'none'}}>
                                                    {column.format
                                                        ? column.format(item)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                        {tableSettings.actions && <TableCell className={styles.tableCellActions} align="right" sx={{border: 'none'}}>
                                            <Box sx={{ display: 'flex', justifyContent: 'end' }}><TableActions actions={tableSettings.actions} rowItem={item}/></Box>
                                        </TableCell>}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
