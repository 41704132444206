import {Box, Tab, Tabs} from '@mui/material'
import React, {useState} from 'react'
import PageWrapper from '../../components/shared/page-wrapper'
import TabPanel from '../../components/shared/tab-panel';
import Preferences from './components/preferences';
import Notifications from './components/notifications';
import Integrations from './components/integrations';
import WorkCalendar from './components/work-calendar';
import {isVisible} from '../../helpers/profileHelper';
import {Part} from '../../features/profile/types';
import {useAppSelector} from '../../hooks/hooks';
import {FormattedMessage} from 'react-intl';

interface TabKey {
    key: string;
    name: string;
    permission: Part;
}
const tabs: TabKey[] = [
    { key: 'preferences', name: 'Preferences',  permission: Part.StartAndEndDayTime },
    { key: 'notifications', name: 'Notifications', permission: Part.NotificationAtEndOfMonth },
    { key: 'integrations', name: 'Integrations', permission: Part.Integrations},
    { key: 'calendar', name: 'Work calendar', permission: Part.WorkCalendar },
]

const tabPanels = [
    { key: 'preferences', panel: <Preferences /> },
    { key: 'notifications', panel: <Notifications /> },
    { key: 'integrations', panel: <Integrations /> },
    { key: 'calendar', panel: <WorkCalendar /> }
]

export default function SettingsPage() {
    const profile = useAppSelector(store => store.profile);
    const [value, setValue] = useState<number>(0);
    const availableTabs = tabs.filter(t => isVisible(profile, t.permission));

    const handleChange = (_event: React.SyntheticEvent, newValue: number ) => {
        setValue(newValue);
    };

    return <PageWrapper>
        <h3><FormattedMessage id={'settings'} /></h3>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} scrollButtons="auto" variant='scrollable' >
                {
                    availableTabs.map((t, index) => {
                        if (isVisible(profile, t.permission)) {
                            return (<Tab disableRipple={true} label={t.name} key={t.key} value={index} />)
                        }
                    })
                }
            </Tabs>
        </Box>
        {
            availableTabs.map((t, index) => (<TabPanel key={t.key} index={index} value={value}>
                { tabPanels.find(x => x.key === t.key)!.panel }
            </TabPanel>))
        }
    </PageWrapper>
};