import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getIntegrations } from '../../../../features/settings/integrations';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import { Integration } from '../../../../features/settings/integrations/types';
import { useIntl } from 'react-intl';
import DisconnectModal from './components/disconnect-modal';
import { store } from '../../../../store/store';
import { selectIdOnDelete, selectIdOnEdit } from '../../../../features/modals';
import ZohoIntegrationBanner from './components/integrations/zoho/banner'
import ZohoConfigurationModal from './components/integrations/zoho/edit-modal';
import { isZohoIntegration } from '../../../../features/integrations/zoho/types';
import { connectIntegration, disconnectIntegration } from './components/integrations/functions';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function Integrations() {
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const selectedDelete = useAppSelector(store => store.modals.selectedDelete);
    const selectedEdit = useAppSelector(store => store.modals.selectedEdit);
    const integrations = useAppSelector(store => store.integrations);
    const [loading, setLoading] = useState<boolean>(true);

    const fetchData = () => {
        setLoading(true);
        dispatch(getIntegrations()).then(() => {
            setLoading(false);
        });
    }

    const handleIntegrationConnectClick = (integration: Integration) => {
        if (integration.enabled) {
            return
        }

        connectIntegration(dispatch, integration)
    }

    const handleIntegrationDisconnectClick = (integration: Integration) => {
        if (!integration.enabled) {
            return
        }

        store.dispatch(selectIdOnDelete(integration));
    }

    const handleIntegrationConfigureClick = (integration: any) => {
        store.dispatch(selectIdOnEdit(integration));
    }

    const handleIntegrationDisconnectConfirm = async (integration: Integration) => {
        dispatch(disconnectIntegration(integration)).then(() => {
            fetchData()
        })
    }

    const handleConfigureComplete = () => {
        fetchData()
    }

    useEffect(() => {
        fetchData();
    }, [integrations.items.length]);

    return (<>
        <Box style={{
            marginTop: '2rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%'
        }}>
            {
                !loading && integrations.items.length > 0 ? integrations.items.map((integration, index) => {
                    if (integration.id == 'zoho') {
                        return <ZohoIntegrationBanner key={index} integration={integration}
                            onConnectClick={handleIntegrationConnectClick}
                            onConfigureClick={handleIntegrationConfigureClick}
                            onDisconnectClick={handleIntegrationDisconnectClick} />
                    } else {
                        return <span key={index}></span>
                    }
                })
                    : !loading && 'There is no available integrations for your workspace.'
            }
        </Box>
        {selectedDelete && <DisconnectModal integration={selectedDelete as Integration} onDisconnectClick={handleIntegrationDisconnectConfirm} />}
        {/* {selectedEdit && <ConfigureIntegrationModal viewModel={selectedEdit as IntegrationViewModel<any, any>} />} */}
        {selectedEdit && isZohoIntegration(selectedEdit) && <ZohoConfigurationModal integration={integrations.items.find(x => x.id == 'zoho')!} integrationData={selectedEdit} onConfigure={handleConfigureComplete} />}
    </>)
}