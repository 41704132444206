import React, {useEffect} from 'react';
import {useAppDispatch} from '../../../../../../hooks/hooks';
import {
    getShiftsTypes,
    updateShiftsType
} from '../../../../../../features/types';
import {TiminatorType} from '../../../../../../features/types/types';
import useModal from '../../../../../../hooks/useModal';
import EditModal from '../../../../../../components/shared/modal/edit-modal';
import * as Yup from 'yup';
import {Form, Formik} from 'formik';
import {FormInput} from '../../../../../../components/shared/form-controls/form-input';
import {FormCheckbox} from '../../../../../../components/shared/form-controls/form-checkbox';

interface Props {
    shiftType: TiminatorType;
}

interface EditForm {
    name: string;
    isDefault: boolean;
    isActive: boolean;
}

const validationScheme: Yup.Schema<EditForm> = Yup.object({
    name: Yup.string()
        .max(45)
        .required('Required'),
    isDefault: Yup.boolean().required(),
    isActive: Yup.boolean().required(),
})

const EditShiftTypesModal = (props: Props) => {
    const dispatch = useAppDispatch();
    const {open, onToggle, openModal} = useModal();
    const id = props.shiftType.id;

    const initialValues: EditForm = {
        name: props.shiftType.name,
        isDefault: props.shiftType.default,
        isActive: props.shiftType.active,
    };

    useEffect(() => {
        openModal();
    }, [])

    const save = async (values: EditForm) => {
        const type: TiminatorType = {
            id,
            name: values.name,
            active: values.isActive,
            default: values.isDefault,
        }

        return dispatch(updateShiftsType(type)).unwrap().then(() => {
            onToggle();
            dispatch(getShiftsTypes(0));
        })
    }

    return <>
        {open && <Formik
            initialValues={initialValues}
            validationSchema={validationScheme}
            onSubmit={save}>
            <Form>
                <EditModal open={open} title='shift type' onClose={onToggle}>
                    <FormInput name='name' label='Name' placeholder='Shift type' />
                    <fieldset>
                        <FormCheckbox name='isDefault' label='Default' />
                        <FormCheckbox name='isActive' label='Active'/>
                    </fieldset>
                </EditModal>
            </Form>
        </Formik>}
    </>
}

export default React.memo(EditShiftTypesModal);
