import { AxiosInstance, AxiosResponse } from 'axios';

export interface IPaymentsApi {
    getState<T>(): Promise<AxiosResponse<T>>;
    setPaymentMethod<T extends string>(callback: string): Promise<AxiosResponse<T>>;
    removePaymentMethod(): Promise<AxiosResponse>;
    doPayment<T>(callback: string): Promise<AxiosResponse<T>>;
}

export default class PaymentsApi implements IPaymentsApi {
    private readonly _axiosInstance: AxiosInstance;
    private readonly controller = 'payments';

    constructor(axiosInstance: AxiosInstance) {
        this._axiosInstance = axiosInstance
    }

    async getState<T>(): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${(this.controller)}/state`);
    }

    async setPaymentMethod<T extends string>(callback: string): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.post<T>(`${this.controller}/set-payment-method?callback=${callback}`);
    }

    async removePaymentMethod(): Promise<AxiosResponse> {
        return await this._axiosInstance.delete(`${this.controller}/remove-payment-method`);
    }

    async doPayment<T>(callback: string): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.post<T>(`${this.controller}/do-payment?callback=${callback}`,);
    }
}