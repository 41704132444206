import React from 'react';
import {Box, Grid} from '@mui/material';
import moment from 'moment';
import DayIcon from '../day/icon';
import {Month} from '../../../../../../features/settings/workcalendar/types';
import style from './month.module.scss';

interface Props {
    onDayClick: (day: number) => void,
    month: Month,
    dateObject: moment.Moment
}

interface Row {
    cells: JSX.Element[]
}

const TOTAL_DAYS = 7;

const MonthView = (props: Props) => {
    const { onDayClick, month, dateObject } = props;

    const className = `${style.monthCell} ${style.weekday}`;
    const weekDayShortNameElements = moment.weekdaysShort(true).map(day => (
        <Grid item key={day} className={className}>
            { day }
        </Grid>
    ));

    const getFirstDayOfMonth = (): number => {
        const momentMonth = moment(dateObject).clone().startOf('month');
        return momentMonth.day();
    }

    const getEmptyDays = () => {
        const blanks: JSX.Element[] = [];
        const firstDayOfMonth = getFirstDayOfMonth();
        const firstDayOfMonthParsed = firstDayOfMonth < 1 ? 6 : firstDayOfMonth - 1; // first  day of week should be monday, so move one
        for (let i = 0; i < firstDayOfMonthParsed; i++ ) {
            blanks.push(<Grid item key={i * 80} className={style.monthCell}>{''}</Grid>);
        }

        return blanks;
    }

    const getDaysInMonth = () => {
        const daysInMonth: JSX.Element[] = [];
        for (let i = 1; i <= dateObject.daysInMonth(); i++) {
            const day = month.days.find(d => d.day === i)!;
            const dayColor = day.isShort ? '#255525' : day.isHoliday ? '#662E2D' : '';
            const hoverColor = day.isShort ? '#163116' : day.isHoliday ? '#431E1D' : '#282727';
            daysInMonth.push(
                <Grid item key={i} className={style.monthCell}>
                    <DayIcon day={i} color={dayColor} hoverColor={hoverColor} onClick={() => onDayClick(i)} />
                </Grid>
            );
        }

        return daysInMonth;
    }

    const getRows = (): Row[] => {
        const totalSlots = [...getEmptyDays(), ...getDaysInMonth()];
        const rows: Row[] = [];
        let cells: JSX.Element[] = [];

        totalSlots.forEach((element, i) => {
            if (i % TOTAL_DAYS !== 0) {
                cells.push(element);
            } else {
                rows.push({ cells: cells });
                cells = [];
                cells.push(element);
            }

            if (i === totalSlots.length - 1) {
                rows.push({ cells: cells });
            }
        })

        return rows;
    }

    return (<Box>
        <Grid container columns={TOTAL_DAYS} className={style.monthWeekdays}>
            { weekDayShortNameElements }
        </Grid>
        <div>
            { getRows().map((row, i) => (<Grid container columns={TOTAL_DAYS} className={style.monthWeeknumbers} key={i} sx={{ marginBottom: 1 }}>
                {
                    row.cells.map((cell) => (cell))
                }
            </Grid>))
            }
        </div>
    </Box>)
}

export default React.memo(MonthView);