import React, {ReactNode, useEffect, useState} from 'react';
import {RowCollapse, State} from './components/row-collapse';
import {
    GridColumnProps,
    GridEditable, GridGroupByProps, GridIdNameModel,
    GridProps,
    GridRowCellProps,
    GridRowModel,
    GridSelectableOptions,
    ValidRowModel
} from './types';
import styles from './grid.module.scss'
import classNames from 'classnames';
import { Button } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import {IntlShape} from 'react-intl';
import Cell from './components/cell';
import EditCell from './components/edit-cell';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {Lx} from '../../../i18n/consts';

import _ from 'lodash';

const GridRowCell = (props: GridRowCellProps) => {
    const { column, row, field, rowEdit, editState, onCellEditStart, onCellEditStop } = props;
    const [editable] = useState<boolean>(() => {
        const editable = column.editable;
        if (!editable) {
            return false
        }
        if (typeof editable === 'function') {
            return editable(row)
        }

        return editable.valueOf()
    });
    const [options, setOptions] = useState<GridIdNameModel[]>(() => {
        const selectableOptions = column.selectableOptions;
        if (!selectableOptions){
            return []
        }
       
        if (typeof selectableOptions === 'function') {
            return []
        }
       
        return selectableOptions
    });
    const [edit, setEdit] = useState<boolean>(false)
    const [value, setValue] = useState<any>(column.value ? column.value(row) : row[field]);
    const [displayValue, setDisplayValue] = useState<any>(column.displayValue ? column.displayValue(value, row, props.intl) : row[field])

    const handleContainerLeave = () => {
        if (!edit) {
            return
        }

        setEdit(false)
    }

    const handleContainerClick = (doubleClick: boolean) => {
        if (!editable) {
            return
        }

        setEdit(true)
        onCellEditStart?.(field, value);
    }

    useEffect(() => {
        if (!value) {
            return;
        }

        setDisplayValue(edit ?
            column.displayValue ?
                column.displayValue(value, row, props.intl)
                : value
            : column.displayValue ?
                column.displayValue(value, row, props.intl)
                : row[field])

        if (!edit) {
            return;
        }

        onCellEditStop?.(field, value)
    }, [value]);

    useEffect(() => {
        const newValue = column.value ? column.value(row) : row[field];
        if (column.selectableOptions && typeof column.selectableOptions === 'function') {
            if (!props.lazyDataLoad) {
                column.selectableOptions(row).then((r) => setOptions(r))
            }
        }
        setValue(newValue)
    }, [row]);

    useEffect(() => {
        if (editState === null) {
            setValue(column.value ? column.value(row) : row[field]);
            setDisplayValue(column.displayValue ?
                column.displayValue(column.value ? column.value(row) : row[field], row, props.intl)
                : row[field])
            setEdit(false);
        }
    }, [editState]);

    useEffect(() => {
        if (edit && !rowEdit) {
            setEdit(false);
        }
    }, [rowEdit]);

    return (<>
        {
            column && column.cellRender ? <div
                className={styles.baseCell}>{!edit ? column!.cellRender(field, row, props.intl) : column.editRender ? column.editRender(field, row, value, (value) => setValue(value))
                    : <EditCell type={column.type ?? 'string'}
                        value={value}
                        onChange={setValue}
                        active={edit} 
                        options={props.lazyDataLoad ? [] : options }
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        getOptions={ props.lazyDataLoad && column.selectableOptions !== undefined && typeof column.selectableOptions === 'function' ? () => column.selectableOptions(row) : undefined } />}
            </div>
                : <Cell onOutsideClick={handleContainerLeave}
                    cellProps={{
                        onClick: () => handleContainerClick(false)
                    }}
                    flex={column.flex ?? false} muted={column.muted ?? false} editable={editable}
                    type={column.type ?? 'string'} width={column.width ? column.width : 'auto'}
                    active={edit}>
                    {edit ? <EditCell type={column.type ?? 'string'} value={value} onChange={(v) => setValue(v)}
                        options={options} active={edit}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        getOptions={ props.lazyDataLoad && column.selectableOptions !== undefined && typeof column.selectableOptions === 'function' ? () => column.selectableOptions(row) : undefined }/>
                        : displayValue
                    }
                </Cell>
        }
    </>)
}

const GridRow = (props: { columns: GridColumnProps[], row: Readonly<ValidRowModel>, intl: IntlShape, lazyDataLoad: boolean, onRowEditStop?: (row: GridRowModel, state: GridRowModel) => void }) => {
    const { columns, row, intl } = props;
    const [editState, setEditState] = useState<GridRowModel | null>(null);
    const [rowEditing, setRowEditing] = useState<boolean>(false);

    const handleBlur = (reason?: string) => {
        if (reason && reason !== 'Escape') {
            return;
        }
        if (rowEditing) {
            setRowEditing(false)
            if (reason === 'Escape') {
                // TODO leave without save
                setEditState(null);
                return;
            }
            if (editState !== null) {
                props.onRowEditStop?.(row, editState);
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleCellEditStart = (field: string, value: any) => {
        if (!rowEditing) {
            setRowEditing(true)
        }
    }

    const handleCellEdit = (field: string, value: any) => {
        setEditState((state) => {
            if (state == null) {
                return {
                    id: 0,
                    [field]: value,
                }
            }

            return {
                ...state,
                [field]: value
            }
        })
    }

    return (<ClickAwayListener onClickAway={() => handleBlur()}>
        {
            row && <div id={'data-row'} onKeyDown={(event) => handleBlur(event.key)} className={classNames({
                [styles.row]: true
            })}>
                <div className={classNames({
                    [styles.rowContainer]: true,
                    [styles.active]: rowEditing
                })}>
                    {
                        columns.filter((x) => x.visible === undefined || x.visible).map((column, index) => {
                            return (<GridRowCell key={index}
                                field={column.field}
                                row={row}
                                column={column}
                                intl={intl}
                                rowEdit={rowEditing}
                                onCellEditStart={handleCellEditStart}
                                onCellEditStop={handleCellEdit} 
                                editState={editState}
                                lazyDataLoad={props.lazyDataLoad}/>)
                        })
                    }
                </div>
            </div>
        }
    </ClickAwayListener>)
}

const TimiDataGrid: React.FC<GridProps> = (props) => {
    const {columns, rows, groupBy, intl: externalIntl, apiRef} = props;
    const [collapseRows, setCollapseRows] = useState<Array<State>>([])
    const [isAnyRowExpand, setIsAnyRowExpand] = useState(false)
    const [init, setInit] = useState(false);
    const intl = externalIntl
    const lazyDataLoad = props.editDataLoadingMode !== undefined && props.editDataLoadingMode === 'server';

    const handleRowStateChange = (state: State) => {
        const index = collapseRows.findIndex((s) => s.rowKey === state.rowKey);
        if (state.open) {
            if (!isAnyRowExpand) {
                setIsAnyRowExpand(true)
                apiRef?.collapseDef.notifySwitch(true);
            }
        } else {
            if (collapseRows.filter((x) => x.open).length < 2) {
                setIsAnyRowExpand(false);
                apiRef?.collapseDef.notifySwitch(false);
            }
        }
        if (index > -1) {
            setCollapseRows((s) => {
                s[index] = state
                return s;
            })
            return
        }

        setCollapseRows((s) => {
            const ns = s.slice();
            ns.push(state);
            return ns;
        })
    };

    const handleExpand = () => {
        if (!isAnyRowExpand) {
            setCollapseRows((s) => {
                s.forEach((s) => s.forceExpand())
                setIsAnyRowExpand(true)
                return s.map((s) => ({...s, open: true}))
            })
            return
        }

        setCollapseRows((s) => {
            s.filter((sx) => sx.open).forEach((sy, index) => {
                sy.forceCollapse()
                s[index].open = false;
            })
            setIsAnyRowExpand(false);
            return s;
        })
    }

    const recursivelyGenerateGroupRows = (groupByProps: GridGroupByProps, groupFields: string[], rows: GridRowModel[], startIndex: number): ReactNode[] => {
        if (startIndex > 0 && groupFields.length < startIndex + 1) {
            return rows.map((r, index) => {
                return (<GridRow key={r.id} columns={columns} row={r} intl={intl}
                    onRowEditStop={props.onRowEditEnd} lazyDataLoad={lazyDataLoad} />)
            })
        }

        const field = groupFields[startIndex];
        return _.chain(rows).groupBy(field).map((value, key) => {
            return( value.length > 1 || startIndex < 1 ? startIndex < 1 ? <RowCollapse nested={startIndex} key={key} rowKey={key}
                rowHeader={groupByProps.groupByRenderer(field, key, value, intl, startIndex + 1)} onStateChange={(startIndex < 1 ? handleRowStateChange : () => void(0))}>
                { recursivelyGenerateGroupRows(groupByProps, groupFields, value, startIndex + 1) }
            </RowCollapse> : <>
                { recursivelyGenerateGroupRows(groupByProps, groupFields, value, startIndex + 1) }
                <div className={styles.row + ' ' + styles.separator} style={{height: '20px'}}></div>
            </> : <>
                <GridRow key={value[0].id} columns={columns} row={value[0]} intl={intl}
                    onRowEditStop={props.onRowEditEnd} lazyDataLoad={lazyDataLoad}/>
                <div className={styles.row + ' ' + styles.separator} style={{height: '20px'}}></div>
            </>)
        }).value()
    }

    useEffect(() => {
        if (!init) {
            return;
        }
        if (!apiRef) {
            return;
        }

        handleExpand();
    }, [apiRef?.collapseDef?.forceSwitch])

    useEffect(() => {
        if (!apiRef) {
            return
        }

        apiRef.collapseDef.notifySwitch(isAnyRowExpand);
    }, [isAnyRowExpand])

    useEffect(() => {
        if (!init) {
            setInit(true);
        }
    }, [])

    return (<div className={styles.container}>
        {
            props.hideHeader || !props.hideHeader && <div className={styles.header}>
                <Button className={classNames({
                    [styles.collapseButton]: true,
                    [styles.expand]: isAnyRowExpand
                })} onClick={() => handleExpand()}><span><ChevronRightIcon /></span><span className={styles.text}>{
                        isAnyRowExpand ? intl.formatMessage({ id: Lx.General.COLLAPSE_ALL }) : intl.formatMessage({ id: Lx.General.EXPAND_ALL })
                    }</span></Button>
                {
                    columns.filter((c) => !c.noHeader && (c.visible === undefined || c.visible)).map((c, index) => (
                        <div key={`column_${index}`} className={classNames({
                            [styles.cell]: true,
                            [styles.flex]: c.flex ? c.flex : false
                        })} style={{ width: c.width ? c.width : 'auto' }}>{c.localizationKey ? intl.formatMessage({ id: c.localizationKey }) : c.headerName}</div>))
                }
            </div>
        }
        <div className={styles.body}>
            {
                !groupBy ? rows.map((r, index) => (
                    <GridRow key={index} columns={columns} row={r} intl={intl} onRowEditStop={props.onRowEditEnd} lazyDataLoad={lazyDataLoad} />))
                    : recursivelyGenerateGroupRows(groupBy, groupBy.rowGroupingModel, rows, 0)
            }
        </div>
    </div>)
}

export default React.memo(TimiDataGrid);
