import React from 'react';
import CreateModal from '../../../../../../components/shared/modal/create-modal';
import CreateNewButton from '../../../../../../components/shared/create-new-button';
import useModal from '../../../../../../hooks/useModal';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {FormInput} from '../../../../../../components/shared/form-controls/form-input';
import {FormCheckbox} from '../../../../../../components/shared/form-controls/form-checkbox';
import {useAppDispatch} from '../../../../../../hooks/hooks';
import {addProjectType, filterProjectType, getProjectTypes} from '../../../../../../features/types';
import {TiminatorType} from '../../../../../../features/types/types';
import { useIntl } from 'react-intl';
import { Lx } from '../../../../../../i18n/consts';

interface CreateForm {
    name: string;
    isDefault: boolean;
    isActive: boolean;
}

export default function CreateProjectTypesModal() {
    const dispatch = useAppDispatch();
    const {open, onToggle} = useModal();
    const intl = useIntl();

    const initialValue: CreateForm = {
        name: '',
        isDefault: false,
        isActive: true,
    };

    const validateName = async (name: string): Promise<boolean> => {
        name = name.toLowerCase();
        if (name === '') {
            return Promise.resolve(false);
        }
        return await dispatch(filterProjectType(name)).unwrap().then((values) => {
            return values.length < 1 || values.some(value => value.name.toLowerCase() !== name);
        });
    }

    const validationScheme: Yup.Schema<CreateForm> = Yup.object({
        name: Yup.string()
            .max(45, 'Must be 45 characters or less')
            .required(intl.formatMessage({ id: Lx.Form.NAME_REQUIRED_FORMAT }, { s: intl.formatMessage({ id: Lx.General.PROJECT_TYPE }) }))
            .test(
                'type-name-backend-validation',
                'project type name is taken',
                async (name) => { return await validateName(name)}),
        isDefault: Yup.boolean().required(),
        isActive: Yup.boolean().required(),
    })

    const add = async (values: CreateForm) => {
        const type: TiminatorType = {
            id: 0,
            name: values.name,
            active: values.isActive,
            default: values.isDefault,
        }
        return dispatch(addProjectType(type)).unwrap().then(() => {
            dispatch(getProjectTypes(0));
            onToggle();
        })
    }

    return <>
        <CreateNewButton onClick={onToggle}/>
        {open && <Formik
            initialValues={initialValue}
            validationSchema={validationScheme}
            validateOnBlur={true}
            validateOnChange={false}
            onSubmit={add}>
            <Form>
                <CreateModal open={open} title='project type' onClose={onToggle}>
                    <FormInput name='name' label='Name' placeholder='Project type' />
                    <fieldset>
                        <FormCheckbox name='isDefault' label='Default' />
                        <FormCheckbox name='isActive' label='Active'/>
                    </fieldset>
                </CreateModal>
            </Form>
        </Formik>}
    </>
}
