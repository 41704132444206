export interface Profile{
    id: number,
    name: string,
    parts: Part[]
}

export enum Part{
    'ProjectTypes'='ProjectTypes',
    'AbsenceTypes'='AbsenceTypes',
    'ShiftTypes'='ShiftTypes',
    'Roles'='Roles',
    'Employees'='Employees',
    'Clients'='Clients',
    'Projects'='Projects',
    'StartAndEndDayTime'='StartAndEndDayTime',
    'WorkCalendar'='WorkCalendar',
    'NotificationAtEndOfMonth'='NotificationAtEndOfMonth',
    'Payments'='Payments',
    'Subscription'='Subscription',
    'EarlyAccess'='EarlyAccess',
    'Integrations'='Integrations',
    'Timesheet'='Timesheet'
}