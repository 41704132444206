import {Column} from '../../../../components/shared/table/types';
import Status from '../../../../components/shared/status';
import {SVGS} from '../../../../assets/images';
import {store} from '../../../../store/store';
import {selectIdOnDelete, selectIdOnEdit} from '../../../../features/modals';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {useTableData} from '../../../../hooks/useTableData';
import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import TiminatorTable from '../../../../components/shared/table';
import IconTextLarge from '../../../../components/shared/large-icon-text';
import {PIdNameModel, Project} from '../../../../features/projects/types';
import {getProjects} from '../../../../features/projects';
import CreateProjectModal from '../create-modal';
import DeleteProjectModal from '../delete-modal';
import EditProjectModal from '../edit-modal';
import {PopupMenuAction} from '../../../../components/shared/popup-menu/types';

const columns: Column[] = [
    {
        id: 'name',
        label: 'Name',
        format: (rowData: Project) => {
            return <span>
                <>
                    <div>{rowData.name}</div>
                    {rowData.employees.length > 0 && <div style={{marginTop: '0.8rem', fontSize: '0.75rem', fontWeight: '400'}}>
                        {rowData.projectManager?.name && <div >👷‍♂️<span style={{marginLeft: '0.3rem'}}>{rowData.projectManager?.name}</span></div>}
                        {rowData.employees.length > 0 && <div>
                        👨‍💼{rowData.employees.map((employee) => <span key={employee.id} style={{marginLeft: '0.3rem'}}>
                                {employee.name}
                            </span>)}
                        </div>}
                    </div>}
                </>
            </span>
        }
    },
    {
        id: 'status',
        label: 'Status',
        isStatus: true,
        align: 'left',
        format: (rowData: Project) => <Status color={rowData.isActive ? '#59CA59' : '#F26D6B'} value={rowData.isActive ? 'Active' : 'Suspended'} />
    },
];

const actions: PopupMenuAction[] = [
    {
        name: 'Edit',
        icon: <SVGS.EditIcon />,
        callback: function(rowData: Project) {
            store.dispatch(selectIdOnEdit(rowData))
        },
    },
    {
        name: 'Delete',
        icon: <SVGS.DeleteIcon />,
        callback: function(rowData: Project) {
            store.dispatch(selectIdOnDelete(rowData))
        },
    }
]

const mapData = (item: Project): Project => item

const sort = (a: Project, b: Project) => {
    if (a.isActive !== b.isActive) {
        return a.isActive ? -1 : 1;
    } else {
        return a.name > b.name ? 1 : -1;
    }
}

interface Props {
    projectType: PIdNameModel,
}

export default function ProjectsList(props: Props) {
    const dispatch = useAppDispatch();
    const projects = useAppSelector(store => store.projects)
    const employees = useAppSelector(store => store.rEmployees)
    const types = useAppSelector(store => store.projects.types)
    const selectedEdit = useAppSelector(store => store.modals.selectedEdit)
    const selectedDelete = useAppSelector(store => store.modals.selectedDelete)
    const clients = useAppSelector(store => store.pClients);
    const eRoles = useAppSelector(store => store.eRoles)
    const tableSettings = useTableData(columns, actions, projects.items, projects.total, mapData, sort);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);

    const fetchData = async (page: number) => {
        dispatch(getProjects({projectTypeId: props.projectType.id, page}))
    }

    useEffect(() => {
        setIsEmpty(projects.total === 0);
    }, [projects.total])

    return  <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
        {
            !isEmpty
                ? <TiminatorTable tableSettings={tableSettings} fetchData={fetchData} offset={projects.offset}/>
                : <IconTextLarge text='You have no projects' subtext='Create project and add people to it' icon={<SVGS.ProjectsIcon />} />
        }
        <CreateProjectModal
            currentType={props.projectType}
            employees={employees}
            types={types}
            clients={clients}
            roles={eRoles}
        />
        {selectedEdit && <EditProjectModal
            project={selectedEdit as Project}
            employees={employees}
            types={types}
            clients={clients}
            roles={eRoles}/>}
        {selectedDelete && <DeleteProjectModal project={selectedDelete as Project}/>}
    </Box>
};
